import { Component, useState } from "react"
import Swal from "sweetalert2"
import { FormContainer, checkableBoolProps, setInputProps } from "../../component/common/forms/Form"
import { ComplaintsBookValidatonSchema } from "../../component/common/forms/constraints/ValidatonSchemas"
import "./reclamacion.scss"
import axios from "axios"
import { projects_data } from "../../data/projects"
import { MailService } from "../../component/common/services/MailService"
import { ComplaintMailPDF } from "../../component/common/Mails/ComplaintMail"

export function Reclamacion() {
    const [initialValues, SetinitialValues] = useState({
        project: "",
        fname: "",
        lname: "",
        mlname: "",
        email: "",
        telephone: "",
        idType: "",
        idNumber: "",
        region: "",
        parentIdType: "",
        parentIdNumber: "",
        parentName: "",
        province: "",
        district: "",
        address: "",
        typeOfGood: "",
        aptNumber: "",
        goodDescription: "",
        complaintType: "",
        complaintDetail: "",
        sellerAction: "",
    })


    const submitForm = async (values, form) => {
        try {
            console.log(values)
            const { data } = await axios.post('https://fbleads.formulaperu.com/api/webhooks/e2bbdaa5-8220-48b8-b207-1e65211f6965', {
                book_number_target: '[BOOK_NUMBER]',
                html: ComplaintMailPDF(values, projects_data.get(values.project))
            });

            if (!data.success) throw new Error(data.message);

            const book = {
                bookNumber: data.bookNumber,
                pdfBase64: `data:application/pdf;base64,${data.pdfBase64}`,
            };

            const b64toBlob = (base64) => fetch(base64).then(res => res.blob());

            const file = await b64toBlob(book.pdfBase64);

            await new MailService()
                .from('Mirano Inmobiliaria <no-reply@mirano.pe>')
                .to(values.email)
                // .to('sistemas@formulaperu.com')
                .subject(`Constancia de reclamo N° ${book.bookNumber} ${values.project}`)
                .text('Libro de reclamaciones')
                .attachment(`Reclamo_N${book.bookNumber}.pdf`, file)
                .send();

            await new MailService()
                .from('Mirano Inmobiliaria <no-reply@mirano.pe>')
                .to('servicioalcliente@padovasac.com')
                .bcc('andrea.flores@formulaperu.com')
                // .to('sistemas@formulaperu.com')
                .subject(`Constancia de reclamo N° ${book.bookNumber} ${values.project}`)
                .text('Libro de reclamaciones')
                .attachment(`Reclamo_N${book.bookNumber}.pdf`, file)
                .send();

            // await axios.post('https://fbleads.formulaperu.com/api/webhooks/ea3d09cb-bf25-49c7-8083-22196845da72', {
            //     sheet_id: '1oRiK9c8j486zxHWngGqTJKCAbE845-woBmvu8qFVEDI',
            //     range: 'Libro de Reclamaciones!A:XX',
            //     rows: [
            //         [
            //             new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' }),
            //             values.project,
            //             'N' + book.bookNumber,
            //             values.fname,
            //             values.flname,
            //             values.mlname,
            //             values.email,
            //             values.phone,
            //             values.idType,
            //             values.idNumber,
            //             values.region,
            //             values.province,
            //             values.district,
            //             values.address,
            //             values.mother_or_father,
            //             values.typeOfGood,
            //             values.aptNumber,
            //             values.complaintType,
            //             values.goodDescription,
            //             values.complaintDetail,
            //             values.consumerPetition,
            //             values.sellerAction,
            //         ],
            //     ],
            // });

            // sessionStorage.setItem('book_pdf', JSON.stringify(book));

            Swal.fire({
                title: 'Datos envíados',
                text: `Estimado/a cliente tu Reclamo/Queja se registró correctamente tu código es: ${data.bookNumber}. Por favor conserva este código ya que con el podrás realizar el seguimiento de tu reclamo/queja en nuestros Centros de Post-venta. Gracias`,
                showDenyButton: true,
                //   showCancelButton: true,
                confirmButtonText: 'Imprimir',
                // confirmButtonColor: "#ff9902",
                denyButtonText: "Finalizar",
                denyButtonColor: "black",
            })
                .then(result => {
                    if (result.isConfirmed) {
                        const downloadLink = document.createElement("a");
                        downloadLink.href = `data:application/pdf;base64,${data.pdfBase64}`;
                        downloadLink.download = `${data.bookNumber}`;
                        downloadLink.click();
                    }
                })
            form.resetForm();
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: '',
                text: '¡Error! Intentelo más tarde',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'red'
            })
        } finally {
            form.setSubmitting(false);
        }
    };

    return (
        <FormContainer
            initialValues={initialValues}
            validationSchema={ComplaintsBookValidatonSchema}
            onSubmit={submitForm}>
            {form => {
                const { handleSubmit, values, errors } = form;
                console.log(errors)
                return (
                    <main className="page --complaints-book">
                        <form onSubmit={handleSubmit} className="form">
                            <div className="form-group col-xs-12">
                                <h1 className="tit-libro  text-center text-uppercase">Libro de Reclamaciones</h1>
                                <p className="descripcion color-blanco  tit-custom-dos">Conforme a lo establecido en el Código de
                                    Protección y Defensa del Consumidor, esta institución cuenta con un Libro de Reclamaciones a su
                                    disposición.
                                </p>
                            </div>
                            <section className="grid-cols-3">
                                <select {...setInputProps("project", "input", form)} >
                                    <option value="">Proyecto</option>
                                    {[...projects_data.entries()].filter(([id, p]) => p.book).map(([id, p]) =>
                                        <option value={id}>{p.name}</option>
                                    )}
                                </select>
                                <input type="text" {...setInputProps("fname", "input", form)} placeholder="Nombre*" />
                                <input type="text" {...setInputProps("lname", "input", form)} placeholder="Apellido Paterno*" />
                                <input type="text" {...setInputProps("mlname", "input", form)} placeholder="Apellido Materno*" />
                            </section>
                            <section className="grid-cols-3">
                                <input type="email"  {...setInputProps("email", "input", form)} placeholder="Correo Electrónico*" />
                                <input type="tel" {...setInputProps("telephone", "input", form)} placeholder="Celular*" />
                                <select {...setInputProps("idType", "input", form)} >
                                    <option value="">Seleccionar</option>
                                    <option value="DNI">DNI</option>
                                    <option value="RUC">RUC</option>
                                    <option value="Carnet de Extranjería">Carnet de Extranjería</option>
                                </select>
                                <input type="text" {...setInputProps("idNumber", "input", form)} placeholder="N° documento" />
                            </section>
                            <section className="grid-cols-3">
                                <input type="text" {...setInputProps("region", "input", form)} placeholder="Departamento*" />
                                <input type="text" {...setInputProps("province", "input", form)} placeholder="Provincia*" />
                                <input type="text" {...setInputProps("district", "input", form)} placeholder="Distrito*" />
                                <input type="text" {...setInputProps("address", "input", form)} placeholder="Dirección*" />
                            </section>
                            <h2 className="">DATOS DEL APODERADO (EN CASO SEA MENOR DE EDAD)</h2>
                            <section className="grid-cols-3">
                                <select {...setInputProps("parentIdType", "input", form)} >
                                    <option value="">Tipo de documento</option>
                                    <option value="DNI">DNI</option>
                                    <option value="RUC">RUC</option>
                                    <option value="Carnet de Extranjería">Carnet de Extranjería</option>
                                </select>
                                <input type="text" {...setInputProps("parentIdNumber", "input", form)} placeholder="Número de documento" />
                                <input type="text" {...setInputProps("parentName", "input", form)} placeholder="Nombres y apellidos" />
                            </section>
                            <h2 className="">IDENTIFICACIÓN DEL BIEN CONTRATADO</h2>
                            <section className="grid-cols-3">
                                <select name="contracted_good_type" {...setInputProps("typeOfGood", "input", form)} >
                                    <option value="" selected="">Tipo de Bien Contratado*</option>
                                    <option value="Producto">Producto</option>
                                    <option value="Servicio">Servicio</option>
                                </select>
                                <input type="text" {...setInputProps("aptNumber", "input", form)} placeholder="Nro. de dpto. o estac.*" />
                            </section>

                            <section className="grid-cols-3">
                                <textarea type="text" {...setInputProps("goodDescription", "input textarea", form)} placeholder="Descripción*" />
                            </section>

                            <h2 className="">DETALLE DE LA RECLAMACIÓN</h2>
                            <section className="grid-cols-3">
                                <select {...setInputProps("complaintType", "input", form)} >
                                    <option value="">Tipo de Reclamo*</option>
                                    <option value="Reclamo">Reclamo</option>
                                    <option value="Queja">Queja</option>
                                </select>
                            </section>
                            <section className="grid-cols-3">
                                <textarea type="text" {...setInputProps("complaintDetail", "input textarea", form)} placeholder="Descripción del reclamo o queja*" />
                            </section>

                            <h2>ACCIONES ADOPTADAS POR EL VENDEDOR</h2>
                            <section className="grid-cols-3">
                                <textarea type="text" {...setInputProps("sellerAction", "input textarea", form)} placeholder="Descripción*" />
                            </section>

                            <div className="flex-center">
                                <button type="submit" className="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "ENVIANDO..." : "ENVIAR"}</button>
                            </div>

                            <div className="block-answer col-xs-12">
                                <div className="values">
                                    <div className="error-message">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </main>
                )
            }}
        </FormContainer>
    )
}
