import { useState } from 'react';
import { dataProjects } from './data';
import './protect.scss';
import file from '../../assets/images/icons/file.svg';
import { Accordion, AccordionDetails, AccordionSummary, Select, Typography } from '@material-ui/core';
export const ProctecionAlConsumidor = () => {
	const [selected, setSelected] = useState(dataProjects[0]);
    const [position, setPosition] = useState(0);
	const addZero = n => {
		return n < 10 ? `0${n}` : n;
	};
	return (
		<main className='relative protecion-al-consumidor'>
			
			<div className='container'>
				<h2 className="title title-horizontal c-primary Display-Thin">Código de protección al consumidor</h2>
				<div class='protected'>
					<div class='menu'>
						<span class='title-section desktop c-black Display-Thin'>Elige el proyecto de tu interés</span>
						<select className='selector mobile' onChange={({target}) => setSelected(dataProjects[target.value])}>
							<option value='1'>Elige el proyecto de tu interés </option>
							{
								dataProjects.map((item, index) => {
									return (
										<option value={index} key={`proyectos-${index}`}>
											{item.project_name}
										</option>
									);
								})
							}
						</select>
						<div class='content-menu'>
							{dataProjects.map((item, index) => {
								return (
									<label className={`title Display-Thin ${selected.project_name === item.project_name ? 'active' : ''}`} onClick={() => setSelected(item)} key={`proyectos-${index}`}>
										{item.project_name}
									</label>
								);
							})}
						</div>
					</div>
					<div className='mobile separator-h'></div>
					<div class='inf'>
						<span class='inf-title Display-Thin c-primary'>Documentos adjuntos</span>
						<div class='inf-business'>
							<div class='item w-100 content-files'>
								<div class='files'>
									{selected?.documents.map((item, index) => {
										if (item.type === 'dir' &&  index < 9) {
											return (
												<Accordion expanded={index === position} square onChange={() => {}} key={`document-${index}`}>
													<AccordionSummary
														// expandIcon={<ExpandMoreIcon />}
														aria-controls='panel2a-content'
														id='panel2a-header'
                                                        onClick={() => setPosition(position === index ? -1 : index)}
													>
														<Typography>
                                                        <img src={file} alt='' className='icon' />
                                                        {addZero(index + 1)}. {item?.name}
                                                        <div className='content-icon acord'>
                                                            <div className='icon-acordeon down'></div>
                                                        </div>
														</Typography>
													</AccordionSummary>
													<AccordionDetails>
                                                        <Typography>
															{
																item?.data.map((item, index) => {
																	return (
																		<a href={item?.file} target={item.file.includes('#') ? '_top':"_blank"} class='sub-file' key={`document-${index}`}>
																			<img src={file} alt='' className='icon' />
																			<span class='file-title c-primary Display-Thin' dangerouslySetInnerHTML={{__html: item?.name}}>
																				
																			</span>
																		</a>
																	)
																})
															}
                                                        </Typography>
                                                    </AccordionDetails>
												</Accordion>
											);
										}
                                        if(index < 9){
                                            return (
                                                <a href={item?.file} target={item.file.includes('#') ? '_top':"_blank"} class='file' key={`document-${index}`}>
                                                    {item?.type === 'file' ? <img src={file} alt='' className='icon' /> : null}
                                                    <span class='file-title c-primary Display-Thin'>
                                                        {addZero(index + 1)}. {item?.name}
                                                    </span>
                                                </a>
                                            );
                                        }
									})}
								</div>
                                <div className='files'>
                                {selected?.documents.map((item, index) => {
										if (item.type === 'dir' &&  index >= 9) {
											return (
												<Accordion expanded={index === position} square onChange={() => {}} key={`document-${index}`}>
													<AccordionSummary
														// expandIcon={<ExpandMoreIcon />}
														aria-controls='panel2a-content'
														id='panel2a-header'
                                                        onClick={() => setPosition(position === index ? -1 : index)}
													>
														<Typography>
                                                        <img src={file} alt='' className='icon' />
                                                        {addZero(index + 1)}. {item?.name}
                                                        <div className='content-icon acord'>
                                                            <div className='icon-acordeon down'></div>
                                                        </div>
														</Typography>
													</AccordionSummary>
													<AccordionDetails>
                                                        <Typography>
														{
																item?.data.map((item, index) => {
																	return (
																		<a href={item?.file} target={item.file.includes('#') ? '_top':"_blank"} class='sub-file' key={`document-${index}`}>
																			<img src={file} alt='' className='icon' />
																			<span class='file-title c-primary Display-Thin' dangerouslySetInnerHTML={{__html: item?.name}}>

																			</span>
																		</a>
																	)
																})
															}
                                                        </Typography>
                                                    </AccordionDetails>
												</Accordion>
											);
										}
                                        if(index >= 9){
                                            return (
                                                <a href={item?.file} target={item.file.includes('#') ? '_top':"_blank"} class='file' key={`document-${index}`}>
                                                    {item?.type === 'file' ? <img src={file} alt='' className='icon' /> : null}
                                                    <span class='file-title c-primary Display-Thin'>
                                                        {addZero(index + 1)}. {item?.name}
                                                    </span>
                                                </a>
                                            );
                                        }
									})}
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};
