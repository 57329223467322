import React, { Component } from 'react'
import './footer.scss'
export default class Footer extends Component {
    openModal = () => {
        let modal = document.querySelector(".modal");
        modal.classList.add("active");
    }
    render() {
        return (
            <div className="footer">
                <div className="container-footer">
                    <div className="brand">
                        <img src={require("../../assets/images/icons/brand-mirano.svg").default} alt="Logo Mirano Inmobiliaria"></img>
                    </div>
                    <div className="center">
                        <div className="row">
                            <a href="nosotros">NOSOTROS</a>|
                            <a href="proyectos">PROYECTOS</a>|
                            <a href="cotizaciones">COTIZACIONES</a>|
                            <a href="contacto">CONTACTO</a>|
                            <a href='/proteccion-al-consumidor'>PROTECCIÓN AL CONSUMIDOR</a>|
                            <a href='/textos-legales'>TEXTOS LEGALES</a>|
                            <a href="Politicas-de-privacidad">PROTECCIÓN DE DATOS</a>
                        </div>
                        <div className="row">
                            <span><a href="tel:+51 966 764 536">966 764 536</a> - <a href="tel:+51 998 159 824">998 159 824</a>|<a>Sala de ventas: Jr. las Poncianas 139, La Molina</a></span>
                        </div>
                        <a className='booking' href='/libro-de-reclamaciones'>
                            <img className='' src={require('../../assets/images/icons/libro-de-reclamaciones.jpg')} ></img>
                        </a>
                        <div className="row">
                            <span className="design">Desarrollado con ♥ por <a href="https://www.formulaperu.com/" target="_blank" className="  ">Fórmula</a></span>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
