export const dataArraySucre = {
	project_name: 'SUCRE 296',
	documents: [
		{
			type: 'file',
			name: 'Datos de proveedores',
			file: 'https://mirano.pe/documentos/sucre/01.%20datos%20de%20proveedores/identificacion%20proveedor%20sucre.pdf',
		},
		{
			type: 'file',
			name: 'Ficha RUC',
			file: 'https://mirano.pe/documentos/sucre/02.%20ficha%20ruc/ficha%20ruc%20forli.pdf',
		},
		{
			type: 'dir',
			name: 'Certificación Edge',
			data: [
				{
					name: 'gp2-per-21030510103165-15_final.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 101.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20101.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 201.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20201.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 202.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20202.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 301.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20301.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 302.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20302.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 401.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20401.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 402.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20402.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 501.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20501.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 502.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20502.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 601.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20601.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 602.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20602.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 701.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20701.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 702.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20702.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 801.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20801.pdf',
				},
				{
					name: 'gp2-per-21030510103165-15_final_dpto. 802.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21030510103165-15_final_dpto.%20802.pdf',
				},
				{
					name: 'gp2-per-21032510104133-2_final.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21032510104133-2_final.pdf',
				},
				{
					name: 'gp2-per-21032510104133-2_final_dpto. 901.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21032510104133-2_final_dpto.%20901.pdf',
				},
				{
					name: 'gp2-per-21032510104133-2_final_dpto. 902.pdf',
					file: 'https://mirano.pe/documentos/sucre/03.%20certificacion%20edge/gp2-per-21032510104133-2_final_dpto.%20902.pdf',
				},
			],
		},
		{
			type: 'file',
			name: 'Memoria descriptiva del proyecto',
			file: 'https://mirano.pe/documentos/sucre/04.%20memoria%20descriptiva%20del%20proyecto/sucre%20292_memoria%20arquitectura.pdf',
		},
		{
			type: 'file',
			name: 'Consideraciones de la certificación Edge',
			file: '#',
		},
		{
			type: 'file',
			name: 'Partida registral del proyecto',
			file: 'https://mirano.pe/documentos/sucre/06.%20partida%20registral%20del%20proyecto/cri%20sucre%20%28forli%29.pdf',
		},
		{
			type: 'file',
			name: 'Poderes del proveedor',
			file: '#',
		},
		{
			type: 'file',
			name: 'Vigencia de poder',
			file: 'https://mirano.pe/documentos/sucre/08.%20vigencia%20de%20poder/vigencia%20forli%20rbz.pdf',
		},
		{
			type: 'dir',
			name: 'Licencia de edificación del proyecto',
			data: [
				{
					name: 'A. Licencia',
					file: 'https://mirano.pe/documentos/sucre/09.%20licencia%20de%20edificacion%20del%20proyecto/Licencia.pdf',
				},
				{
					name: 'B. Licencia cambio prelosas',
					file: 'https://mirano.pe/documentos/sucre/09.%20licencia%20de%20edificacion%20del%20proyecto/Licencia%20cambio%20prelosas',
				},
				{
					name: 'C. Licencia modificación',
					file: 'https://mirano.pe/documentos/sucre/09.%20licencia%20de%20edificacion%20del%20proyecto/Licencia%20modificaci%C3%B3n',
				},
			],
		},
		{
			type: 'file',
			name: 'Relación de acabados',
			file: 'https://mirano.pe/documentos/sucre/10.%20relacion%20de%20acabados/sucre%20296%20acabados%20dpto%20tipico.pdf',
		},
		{
			type: 'file',
			name: 'Condiciones generales sobre el precio de compra',
			file: 'https://mirano.pe/documentos/sucre/11.%20condiciones%20generales%20sobre%20el%20precio%20de%20compra/consideraciones%20sobre%20el%20precio%20de%20venta.pdf',
		},
		{
			type: 'dir',
			name: 'Modelo de contrato de separación',
			data: [
				{
					name: 'A. Modelo de minuta sucre',
					file: 'https://mirano.pe/documentos/sucre/12.%20modelo%20de%20contrato%20de%20separacion/Modelo%20de%20Minuta%20Sucre',
				},
				{
					name: 'B. Modelo de separación Sucre',
					file: 'https://mirano.pe/documentos/sucre/12.%20modelo%20de%20contrato%20de%20separacion/B.%20Modelo%20de%20separaci%C3%B3n%20Sucre.docx.pdf',
				},
			],
		},
		{
			type: 'file',
			name: 'Recomendaciones generales',
			file: '#',
		},
		{
			type: 'file',
			name: 'HR y PU 2024',
			file: 'https://mirano.pe/documentos/sucre/14.%20hr%20y%20pu%202024/HR%20y%20PU%202024%20Sucre',
		},
		{
			type: 'dir',
			name: 'Comprobante de pago del impuesto predial del 2023',
			data: [
				{
					name: 'A. Predial 1 y 1, 2 arbitrios',
					file: 'https://mirano.pe/documentos/sucre/15.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/predial%201%20y%201%2C%202%20arbitrios.pdf',
				},
				{
					name: 'B. Predial 2, 3 y 4 trimestre',
					file: 'https://mirano.pe/documentos/sucre/15.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/predial%202%2C%203%20y%204%20trimestre.pdf',
				},
			],
		},
		{
			type: 'dir',
			name: 'Planos de proyecto aprobados',
			data: [
				{
					name: 'A. Arquitectura',
					file: 'https://mirano.pe/documentos/sucre/16.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/arquitectura.zip',
				},
				{
					name: 'B. Electricas',
					file: 'https://mirano.pe/documentos/sucre/16.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/electricas.zip',
				},
				{
					name: 'C. Estructuras',
					file: 'https://mirano.pe/documentos/sucre/16.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/estructuras.zip',
				},
				{
					name: 'D. Sanitarias',
					file: 'https://mirano.pe/documentos/sucre/16.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/sanitarias.zip',
				},
				{
					name: 'E. Seguridad',
					file: 'https://mirano.pe/documentos/sucre/16.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/seguridad.zip',
				},
			],
		},
        {
			type: 'dir',
			name: 'Factibilidad de servicios',
			data: [
				{
                    name:"A. LDS.zip",
                    file:"https://mirano.pe/documentos/sucre/17.%20factibilidad%20de%20servicios/luz_del_sur.zip",
                },{
                    name:"B. Sedapal.zip",
                    file:"https://mirano.pe/documentos/sucre/17.%20factibilidad%20de%20servicios/sedapal.zip",
                },
			],
		},
        {
			type: 'dir',
			name: 'Conformidad de obra',
			data: [
				{
                    name:"Conformidad de obra",
                    file:"https://mirano.pe/documentos/sucre/18.%20conformidad%20de%20obra/Conformidad%20de%20obra",
                },{
                    name:"Planos Arq y Seg.zip",
                    file:"https://mirano.pe/documentos/sucre/18.%20conformidad%20de%20obra/Planos%20Arq%20y%20Seg.zip",
                },
			],
		},
		{
			type: 'file',
			name: 'Identificación del proveedor',
			file: 'https://mirano.pe/documentos/sucre/Identificacion%20del%20proveedor.pdf',
		},
	],
};
