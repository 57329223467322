import React, { Component , createRef, useState } from 'react'
import { Link } from "react-router-dom";
import './navbar.scss'
import MediaQuery from 'react-responsive';

export default class Navbar extends Component {
    constructor(props){
        super(props)
        this.activePosition = this.activePosition.bind(this)
        this.close = this.close.bind(this)
        this.toggle = this.toggle.bind(this)
        this.toggleLink = this.toggleLink.bind(this)
        this.offset = this.offset.bind(this)
        this.moveTo =  this.moveTo.bind(this)
        this.state = {
            activeLine:1,
            activeNav:true,
            initTransparent:true,
            expanded:"panel5",
            closeMore:false,
            toggle:false,
            links:[
                {index:0,title:"INICIO",link:"/",initTransparent:true},
                {index:1,title:"NOSOTROS",link:"/nosotros",initTransparent:true},
                {index:2,title:"PROYECTOS",link:"/proyectos",linkExternal:true,subLink:[
                    {index:2,title:"LITORAL 900",link:"https://litoral900.pe/",class:"mobile",initTransparent:true},
                    {index:2,title:"MONTE UMBROSO 122",link:"/Chacarilla/Monte-Umbroso-122",class:"mobile"},
                    // {index:2,title:"TUDELA Y VARELA 445",link:"/Miraflores/Tudela-y-Varela-445",class:"mobile"},
                    // {index:2,title:"VILLAVICENCIO 1320",link:"/Lince/Villavicencio-1320",class:"mobile"},
                    // {index:2,title:"PASTEUR 1228",link:"/Lince/Pasteur-1228",class:"mobile"},
                ]},
                {index:3,title:"CONTACTO",link:"/contacto",initTransparent:false},
                {index:4,title:"CONTACTO",link:"/proteccion-al-consumidor",initTransparent:false,show:false},
                // {index:4,title:"COTIZACIONES",link:"/?"},
                {index:null,title:"POLÍTICA DE PRIVACIDAD",link:"/Politicas-de-privacidad",show:false,initTransparent:false},
                {index:null,title:"Proximamente",link:"/Litoral-900",show:false,initTransparent:false},
                {index:null,title:"Proximamente",link:"/litoral-900",show:false,initTransparent:false},
                {index:null,title:"PROMOCIONES",link:"/outlet_wow",show:false,initTransparent:false},
                {index:null,title:"Avance de obra",link:"/avance-de-obra",show:false,initTransparent:false},
                {index:null,title:"Libro de reclamaciones",link:"/libro-de-reclamaciones",show:false,initTransparent:false},
            ]
        }
        this.nav = React.createRef()
    }
    componentDidMount(){
       
        let data;
        let location = this.state.links.filter((item,index)=>{
            if( window.location.href.includes(item.link)){
                console.log("show item:",item.link)
                this.setState({
                    activeLine:item.index,
                    initTransparent:item.initTransparent,
                    activeNav:item.initTransparent == true ? false : true
                })
                data = item.index
            }
            return data            
        })
        window.onscroll = () => {
            if(this.state.initTransparent == true){
                window.scrollY == 0 ? this.setState({activeNav:false}): this.setState({activeNav:true});
            }
        }
    }
    activePosition(e,position){
        this.setState({activeLine:position})
        this.toggleLink()
        if(window.location.search.includes("proyectos")){
            this.moveTo("#inicio-page","#all-proyectos")
        }   
    }
    close(e){
        this.setState({closeMore:true})
    }
    toggleLink(e){
        this.setState({toggle:!this.state.toggle})
    }
    toggle(e){
        this.setState({toggle:!this.state.toggle})
    }
    moveTo(elementFrom,elementTo){
        let navHeight = document.querySelector("#nav").clientHeight
        if(document.querySelector(elementTo)){
            window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight-50))
        }
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
    
    render() {
        return (
            <nav ref={this.nav} id="nav" className={`navbar ${this.state.activeNav ? "active" : ""}`}>
                <div className="container content-nav">
                    <Link to="/"className="brand">
                        <img className="logo-white" src={require("../../assets/images/icons/brand-mirano.svg").default} alt="Logo Mirano Inmobiliaria"></img>
                    </Link>
                    <MediaQuery query="(min-width: 1200px)">
                        <OptionNav options={this.state} position={this.activePosition} ></OptionNav>
                    </MediaQuery>
                    <MediaQuery query="(max-width: 1200px)">
                        <div className={`toggle ${this.state.toggle == true ? "active" : ""}`} onClick={(e)=>{this.toggle(e)}}>
                            <div className="line line-1"></div>
                            <div className="line line-2"></div>
                        </div>
                    </MediaQuery>
                </div>
                <MediaQuery query="(max-width: 1200px)">
                    <OptionNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionNav>
                </MediaQuery>
            </nav>
        )
    }
}

class OptionNav extends Component{
    constructor(props){
        super(props)
        this.state = {
            expanded:""
        }
    }
    handleChange = (panel) => (event, newExpanded) => {
        this.setState({expanded: newExpanded ? panel : false})
    };
    render(){
        return(
        <div className={`container-link ${this.props.options.toggle ? "active" : ""}`}>
        <div className="links">
            <div className="center">
                {
                    this.props.options.links.map((item,index)=>{
                        if(item.show != false){
                            if(item.linkExternal == true){
                                return(
                                    <a href={item.link} id={item.id} className={`link ${this.props.options.activeLine == index ? "active" : ""}`}
                                        onClick={(e)=>{this.props.position(e,item.index)}}>
                                        <div className="content-text cart">
                                            <span className="text-link cart">{item.title}</span>
                                            <span className="barra-line"></span>
                                            {item.subLink ? 
                                                <div className='content-sublink'>
                                                    {
                                                        item.subLink.map((sublink)=>{
                                                            if(sublink.linkExternal == true){
                                                                return(
                                                                    <a href={sublink.link} target="_blank" className='sub-link c-white'>{sublink.title}</a>
                                                                )
                                                                
                                                            }else{
                                                                return(
                                                                    <a href={sublink.link} className='sub-link c-white'>{sublink.title}</a>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div> : null
                                            }
                                        </div>
                                    </a>
                                )

                            }else{
                                return(
                                    <Link to={item.link} id={item.id} className={`link ${"link-"+item.title} ${this.props.options.activeLine == index ? "active" : ""}`}
                                        onClick={(e)=>{this.props.position(e,item.index)}}>
                                        <div className="content-text cart">
                                            <span className="text-link cart">{item.title}</span>
                                            <span className="barra-line"></span>
                                            {item.subLink ? 
                                                <div className='content-sublink'>
                                                    {
                                                        item.subLink.map((sublink)=>{
                                                            if(sublink.linkExternal == true){
                                                                return(
                                                                    <a href={sublink.link} target="_blank" className='sub-link c-white'>{sublink.title}</a>
                                                                )
                                                                
                                                            }else{
                                                                return(
                                                                    <Link to={sublink.link} className='sub-link c-white'>{sublink.title}</Link>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div> : null
                                            }
                                        </div>
                                    </Link>
                                )
                            }
                            
                        }
                    })
                }
            </div>
            <div className="link-buttons">
                <a  target="_blank" href="https://www.facebook.com/miranoinmobiliaria" className={`link button`}>
                    <div className="redes mask facebook">
                    </div>
                </a>
                <a target="_blank" href="https://www.instagram.com/mirano_inmobiliaria" className={`link button`}>
                    <div  className="redes mask instagram">
                    </div>
                </a>
            </div>
        </div>
    </div>
    )
    }
}