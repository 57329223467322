import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string()
        .required(),
    lname: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.number()
        .integer()
        .test('len', val => val?.toString()?.length >= 7 && val?.toString()?.length <= 15)
        .required(),
}

export const BaseValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ContactValidationSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        medium: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const HomeValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ProjectValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })


export const ComplaintsBookValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        mlname: Yup.string()
            .required(),
        parentIdType: Yup.string(),
        parentIdNumber: Yup.string(),
        parentName: Yup.string(),
        idType: Yup.string()
            .required(),
        idNumber: Yup.string()
            .required(),
        region: Yup.string()
            .required(),
        province: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        address: Yup.string()
            .required(),
        typeOfGood: Yup.string()
            .required(),
        aptNumber: Yup.string()
            .required(),
        goodDescription: Yup.string()
            .required(),
        complaintType: Yup.string()
            .required(),
        complaintDetail: Yup.string()
            .required(),
        sellerAction: Yup.string()
            .required()
    })