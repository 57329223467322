export const dataArrayMU = {
	project_name: 'MONTE UMBROSO 122',
	documents: [
		{
			type: 'file',
			name: 'Datos de proveedores',
			file: 'https://mirano.pe/documentos/mu/01.%20datos%20de%20proveedores/identificacion%20proveedor%20monte%20umbroso.pdf',
		},
        {
			type: 'file',
			name: 'Ficha RUC',
			file: 'https://mirano.pe/documentos/mu/02.%20ficha%20ruc/ficha%20ruc%20borgo.pdf',
		},
        {
			type: 'file',
			name: 'Certificación EDGE',
			file: 'https://mirano.pe/documentos/mu/03.%20certificacion%20edge/gp2-per-23031410158354-13_final.pdf',
		},
        {
			type: 'file',
			name: 'Memoria descriptiva del proyecto',
			file: 'https://mirano.pe/documentos/mu/04.%20memoria%20descriptiva%20del%20proyecto/p_monte%20umbroso_md.pdf',
		},
        {
			type: 'file',
			name: 'Consideraciones de la certificación EDGE',
			file: '#',
		},
        {
			type: 'file',
			name: 'Partida registral del proyecto',
			file: 'https://mirano.pe/documentos/mu/06.%20partida%20registral%20del%20proyecto/copia%20literal%20monteumbroso.pdf',
		},
        {
			type: 'file',
			name: 'Poderes del proveedor',
			file: '#',
		},
        {
			type: 'file',
			name: 'Vigencia de poder',
			file: 'https://mirano.pe/documentos/mu/08.%20vigencia%20de%20poder/vigencia%20borgo%20rbz.pdf',
		},
        {
			type: 'dir',
			name: 'Licencia de edificación del proyecto',
			data: [
                {
                    name: 'Licencia  1.pdf',
                    file:"https://mirano.pe/documentos/mu/09.%20licencia%20de%20edificacion%20del%20proyecto/licencia%201.pdf"
                },
                {
                    name: 'Licencia  2.pdf',
                    file:"https://mirano.pe/documentos/mu/09.%20licencia%20de%20edificacion%20del%20proyecto/licencia%20%202.pdf"
                },
            ],
		},
        {
			type: 'file',
			name: 'Relación de acabados',
			file: 'https://mirano.pe/documentos/mu/10.%20relacion%20de%20acabados/lista%20de%20acabados.pdf',
		},
        {
			type: 'file',
			name: 'Condiciones generales sobre el precio de compra',
			file: 'https://mirano.pe/documentos/mu/11.%20condiciones%20generales%20sobre%20el%20precio%20de%20compra/consideraciones%20sobre%20el%20precio%20de%20venta.pdf',
		},
        {
			type: 'dir',
			name: 'Modelo de contrato de separación',
			data: [
                {
                    name: 'Acuerdo de separación monte umbroso.pdf',
                    file:"https://mirano.pe/documentos/mu/12.%20modelo%20de%20contrato%20de%20separacion/acuerdo%20de%20separaci%C3%B3n%20monte%20umbroso.pdf"
                },
                {
                    name: 'Modelo de minuta monte umbroso.pdf',
                    file:"https://mirano.pe/documentos/mu/12.%20modelo%20de%20contrato%20de%20separacion/modelo%20de%20minuta%20monte%20umbroso.pdf"
                },
            ],
		},
        {
			type: 'file',
			name: 'Recomendaciones generales',
			file: '#',
		},
        {
			type: 'file',
			name: 'HR y PU 2024',
			file: 'https://mirano.pe/documentos/mu/14.%20hr%20y%20pu%202024/hr%20-%20pu%202024%20-%20borgo.pdf',
		},
        {
			type: 'dir',
			name: 'Comprobante de pago del impuesto predial del 2023',
			data: [
                {
                    name: 'Impuesto predial 2023.pdf',
                    file:"https://mirano.pe/documentos/mu/15.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/impuesto%20predial%202023.pdf"
                },
                {
                    name: 'Predial 2024.jpeg',
                    file:"https://mirano.pe/documentos/mu/15.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/predial%202024.jpeg"
                },
            ],
		},
        {
			type: 'dir',
			name: 'Planos del proyecto aprobados por la municipalidad',
			data: [
                {
                    name: 'A. Arquitectura',
                    file:"https://mirano.pe/documentos/mu/16.%20planos%20de%20prtoyecto%20aprobados%20por%20la%20municipalidad/arquitectura.zip"
                },
                {
                    name: 'B. Electricas',
                    file:"https://mirano.pe/documentos/mu/16.%20planos%20de%20prtoyecto%20aprobados%20por%20la%20municipalidad/electricas.zip"
                },
                {
                    name: 'C. Estructuras',
                    file:"https://mirano.pe/documentos/mu/16.%20planos%20de%20prtoyecto%20aprobados%20por%20la%20municipalidad/estructuras.zip"
                },
                {
                    name: 'D. Sanitarias',
                    file:"https://mirano.pe/documentos/mu/16.%20planos%20de%20prtoyecto%20aprobados%20por%20la%20municipalidad/sanitarias.zip"
                },
                {
                    name: 'E. Seguridad',
                    file:"https://mirano.pe/documentos/mu/16.%20planos%20de%20prtoyecto%20aprobados%20por%20la%20municipalidad/seguridad.zip"
                },
            ],
		},
        {
			type: 'dir',
			name: 'Factibilidad de servicios',
			data: [
                {
                    name: 'LDS.zip',
                    file:"https://mirano.pe/documentos/mu/17.%20factibilidad%20de%20servicios/lds.zip"
                },
                {
                    name: 'Sedapal.zip',
                    file:"https://mirano.pe/documentos/mu/17.%20factibilidad%20de%20servicios/sedapal.zip"
                },
            ],
		},
        {
			type: 'dir',
			name: 'Conformidad de obra',
			data: [
                {
                    name: 'Conformidad obra.pdf',
                    file:"https://mirano.pe/documentos/mu/18.%20conformidad%20de%20obra/fue%20conformidad%20obra.pdf"
                },
                {
                    name: 'Planos de conformidad.zip',
                    file:"https://mirano.pe/documentos/mu/18.%20conformidad%20de%20obra/planos%20de%20conformidad.zip"
                },
            ],
		},
    ],
};
