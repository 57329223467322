import { PROJECTS, PROJECT_CRM } from "./data";

const Litoral900 = {
    project:"litoral",
    name: PROJECTS.l900,
    crm: PROJECT_CRM[PROJECTS.l900],
    book: {
        ruc: "20606681985",
        corporateName: "Inversiones Sauris SAC",
        partida: 14545892,
        address: "Jr. Las Poncianas 139 La Molina"
    },
}
export default Litoral900;