import React from 'react';

const Data = React.createContext("data");
export default Data;

export const PROJECTS = {
    sm230: "SAN MARTÍN 230",
    mu122: "MONTE UMBROSO 122",
    s296: "SUCRE 296",
    tyv445: "TUDELA Y VARELA 445",
    p1228: "PASTEUR 1228",
    p1250: "PASTEUR 1250",
    v1320: "VILLAVICENCIO 1320",
    l900: "LITORAL 900",
}
export const PROJECT_CRM = {
    [PROJECTS.sm230]: {id: 1536, evoltaEmail: "san_martin_230_mirano@evolta.pe", from_mail_wsp:[/** Luis Martinez **/["webinmobiliaria@formulaperu.com", 51994209947],["webinmobiliaria2@formulaperu.com", 51994209947]]},
    [PROJECTS.mu122]: {id: 1537, evoltaEmail: "monte_umbroso_122@evolta.pe"},
    [PROJECTS.s296]: {id: 1496, evoltaEmail: "mariscal_sucre_296_padova@evolta.pe"},
    [PROJECTS.tyv445]: {id: 288, evoltaEmail: "padova_tudelavarela445@evolta.pe", from_mail_wsp:[/** Usher **/["webinmobiliaria@formulaperu.com", 51966764536],["webinmobiliaria2@formulaperu.com", 51966764536]]},
    [PROJECTS.p1228]: {id: 1334, evoltaEmail: "luis_pasteur_1228_padova@evolta.pe"},
    [PROJECTS.p1250]: {id: 286, evoltaEmail: "padova_pasteur_1250@evolta.pe"},
    [PROJECTS.v1320]: {id: 254, evoltaEmail: "villavicencio_Mirano@evolta.pe"},
    [PROJECTS.l900]: {id: 1894, evoltaEmail: "litoral_900_padova@evolta.pe"},
}