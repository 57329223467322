
import axios from "axios";

export class MailService {
    #payload;
    #client;
    constructor() {
        this.#payload = new FormData()
        this.#client = axios.create({
            baseURL: "https://cors.formulaperu.com/https://api.mailgun.net/v3/mg.formulaperu.com",
            auth: {
                username: 'api',
                password: 'key-2f8526fda8b88fce4bc2fd3f1858cca7'
            }
        })
    }

    from(from) {
        this.#payload.set("from", from)
        return this
    }

    to(to) {
        this.#payload.set("to", to)
        return this
    }

    bcc(to) {
        this.#payload.set("bcc", to)
        return this
    }

    subject(subject) {
        this.#payload.set("subject", subject)
        return this
    }

    html(html) {
        this.#payload.set("html", html)
        return this
    }

    text(text) {
        this.#payload.set("text", text)
        return this
    }

    attachment(fileName, attachment) {
        this.#payload.append("attachment", attachment, fileName)
        return this
    }

    send() {
        return this.#client.post('messages', this.#payload)
    }
}