export const BienvenidaEmail = data => `
<table align="center" border="0" cellpadding="0" cellspacing="0" style="background-color: white;color: #818181;font-family: gotham-book;">
	<tbody>
        <tr>
	        <td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<img src="https://mirano.pe/images/mail/bienvenida-top.png">
	        </td>
        </tr>
    <tr>
      <td style="text-align: center;font-size: 1.3em;font-family: sans-serif;background-color:white;color: #19265a;">
        <p style="margin: 1em;color: #19265a;text-transform: uppercase;font-weight: bold;font-size: xx-large;">¡Hola, ${data.fname}!</p>
        <p style="margin-bottom:0;">Gracias por registrarte en nuestra Lista Preferente.</p>
        <p style="margin:0;"><b>Serás uno de los primeros en descubrir</b></p>
        <p style="margin:0;"><b>la magia de ver el mar.</b></p>
        <hr style="width:10%;border-top: 1px solid #19265a;margin-top:1em;margin-bottom:1em;"/>
        <p style="margin-bottom:0;">Pronto nuestro asesor te contactará para brindarte</p>
        <p style="margin:0;">mayor información de nuestros departamentos</p>
        <p style="margin:0;margin-bottom:2em">de 1 y 2 dormitorios</p>
      </td>
    </tr>
	<tr>
		<td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<img src="https://mirano.pe/images/mail/bienvenida-bottom.png" usemap="#image-map">
		</td>
	</tr>
	</tbody>
</table>
`