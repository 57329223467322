export const ComplaintMailPDF = (fields, project) => {
	const date = new Date();

	return `
    <!DOCTYPE html>
    <html lang="es-PE">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Libro de reclamación</title>
    </head>
    <style>
        * {
            font-family: Arial, Helvetica, sans-serif;
            box-sizing: border-box;
        }
        .b-gray{
            background-color: rgb(177, 176, 176);
        }
        .container{
            width: 900px;
        }
        .border{
            border: 1px solid ;
        }
    </style>
    <body>
        <h2 style="text-align:center">ANEXOS</h2>
        <h2 style="text-align:center;font-size: 1rem;">Anexo I: Formato de Hoja de Reclamación del Libro de Reclamaciones</h2>
        <div style="margin: auto;margin-bottom: 2rem;" class="container">
            <div style="display:flex">
                <div class="" style="width: 50%; border: 1px solid;">
                    <div class="b-gray" style="height:2rem;padding: 0 1rem;text-align:center;display: flex;align-items: center;justify-content: center;">
                        <strong>LIBRO DE RECLAMACIONES</strong>
                    </div>
                    <div style="width: 100%; height:2rem;display: flex;    border-bottom: 0;">
                        <div style="font-size: .9rem; border: 1px solid;border-bottom: 0;border-left:0;min-width: 10rem;display: flex;align-items: center;justify-content: center;">FECHA:</div>
                        <div style="font-size: .9rem; width: 100%;border-top: 1px solid;display:flex;justify-content:center;align-items:center;">${date.getDate()}</div>
                        <div style="font-size: .9rem; width: 100%;border-top: 1px solid;border-left: 1px solid;display:flex;justify-content:center;align-items:center;">${date.getMonth() + 1}</div>
                        <div style="font-size: .9rem; width: 100%;border-top: 1px solid;border-left: 1px solid;display:flex;justify-content:center;align-items:center;">${date.getFullYear()}</div>
                    </div>
                </div>
                <div class="border" style="font-size:.9rem;width: 50%;display: flex;align-items: center;justify-content: center;flex-direction: column;border-left: 0;">
                    <span>HOJA DE RECLAMACIÓN</span>
                    <span>N° [BOOK_NUMBER]</span>
                </div>
            </div>
            <div class="border" style="font-size: .9rem;font-weight:bold;padding: .5rem 1rem;border-top: 0;">${project.name} / ${project.book.corporateName} / ${project.book.ruc} / ${project.book.address} / ${project.book.partida ?? ''}
            </div>
            <div class="b-gray" style="height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;border-left: 1px solid;border-right: 1px solid;">
                1. IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANTE
            </div>
            <div class="border" style="border-bottom:0;height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;">
                NOMBRE: ${fields.fname} ${fields.lname} ${fields.mlname}
            </div>
            <div class="border" style="border-bottom:0;height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;">
                DOMICILIO: ${fields.address}, ${fields.district}, ${fields.province}, ${fields.region}
            </div>
            <div class="border d-flex" style="border-bottom:0;height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: row;width: 100%;">
                <div style="width: 30%;display: flex;align-items: center;border-right: 1px solid black;">${fields.idType}: ${fields.idNumber}</div>
                <div style="width: 70%;display: flex;align-items: center;padding-left: 1rem;">TELÉFONO / E-MAIL: ${fields.telephone} / ${fields.email}</div>
            </div>
            <div class="border" style="border-bottom:0;height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;">
                PADRE / MADRE / TUTOR (PARA EL CASO DE MENORES DE EDAD), REPRESENTANTE / CURADOR
            </div>
            <div class="border" style="border-bottom:0;height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;">
                DOC: ${fields.parentIdType} ${fields.parentIdNumber} NOMBRE: ${fields.parentName}
            </div>
            <div class="b-gray border" style="height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;border-bottom: 0;">
                2. IDENTIFICACIÓN DEL BIEN CONTRATADO
            </div>
            <div class="border" style="display:flex;border-bottom:0;font-size:.9rem;display: flex;flex-direction: row;width: 100%;">
                <div style="display: flex;flex-direction: column;width: 10rem;">
                    <div style="display: flex;"><div style="border: 1px solid;border-top:0;border-left:0;border-right:0;padding: 1rem;width: 8rem;">PRODUCTO</div><div class="border" style="width: 2rem;border-top: 0;display:flex;justify-content:center;align-items:center;">${
						fields.typeOfGood.toLowerCase().includes('producto') ? 'X' : ''
					}</div></div>
                    <div style="display: flex;"><div style="border: 1px solid;border-top:0;border-left:0;border-right:0;padding: 1rem;width: 8rem;border-bottom: 0;">SERVICIO</div><div class="border" style="width: 2rem;border-top: 0;display:flex;justify-content:center;align-items:center;border-bottom: 0;">${
						fields.typeOfGood.toLowerCase().includes('servicio') ? 'X' : ''
					}</div></div>
                </div>
                <div style="padding:1rem;display:flex;align-items:center;justify-content: center ;flex-direction: column;">
                    <div style="width: 100%;margin-bottom: .5rem;"><span>NRO. DPTO. / ESTAC.: </span>${fields.aptNumber}</div>
                    <div style="width: 100%;"><span>DESCRIPCIÓN: </span>${fields.goodDescription}</div>
                </div>
            </div>
            <div class="b-gray border" style="height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;width: 100%;padding-right: 0;border-bottom: 0;">
                <div style="display: flex;align-items: center;">
                    3. DETALLE DE LA RECLAMACIÓN Y PEDIDO DEL CONSUMIDOR
                </div>
                <div style="width: 10rem;margin-left:auto;border-left: 1px solid; display: flex;align-items: center; padding-left:1rem; background-color: white;">
                    RECLAMO
                    <div id="reclamo" style="height: 100%;width: 2rem;border-left: 1px solid;border-right: 1px solid;margin-left: auto;display:flex;justify-content:center;align-items:center;">
                    ${fields.complaintType.toLowerCase().includes('reclamo') ? 'X' : ''}
                    </div>
                </div>
                <div style="width: 10rem;border-left:0; display: flex;align-items: center; padding-left:1rem;background-color: white;">
                    QUEJA
                    <div id="reclamo" style="height: 100%;width: 2rem;border-left: 1px solid;border-right: 0;margin-left: auto;display:flex;justify-content:center;align-items:center;">
                    ${fields.complaintType.toLowerCase().includes('queja') ? 'X' : ''}
                    </div>
                </div>
            </div>
            <div class="border" style="display: flex;flex-direction: column;">
                <div style="width: 100%;padding:1rem 1rem;min-height: 7rem;">
                    <p style="margin: 0;font-size:.9rem;"><span>DESCRIPCIÓN DEL RECLAMO O QUEJA: </span><span id="text-detalle">${fields.complaintDetail}</span></p>
                </div>
            </div>
            <div class="b-gray border" style="height:2rem;padding:0 1rem;font-size:.9rem;width: 50%;display: flex;justify-content: center;flex-direction: column;width: 100%;border-bottom: 0;border-top: 0;">
                4. OBSERVACIONES Y ACCIONES ADOPTADAS POR EL PROVEEDOR
            </div>
            <div class="border" style="display: flex;min-height: 10rem;">
                <div style="width: 100%;">
                    <div style="width:100%;height: 2rem;border: 1px solid;border-left: 0;border-right: 0;border-top: 0;font-size: .9rem;padding: 0 1rem;align-items: center;display: flex;">
                        FECHA DE COMUNICACIÓN DE LA RESPUESTA
                        <div style="margin-left: auto;width: 3rem;height:100%;border-left: 1px solid;display: flex;align-items: center;justify-content: center;text-align: center;padding: 0 .5rem;">

                        </div>
                        <div style="width: 8rem;height:100%;border-left: 1px solid;display: flex;align-items: center;justify-content: center;text-align: center;padding: 0 .5rem;">
                        </div>
                        <div style="width: 3rem;height:100%;border-left: 1px solid;display: flex;align-items: center;justify-content: center; text-align: center;padding: 0 .5rem;">

                        </div>
                    </div>
                    <div style="padding:1rem 1rem;">${fields.sellerAction}</div>
                </div>
            </div>
            <div style="display: flex;border-left: 1px solid;border-right: 1px solid; height: 4rem;border-bottom: 1px solid;">
                <div style="padding:.5rem 1rem;font-size: .9rem; width: 50%; height: 100%;border-right: 1px solid;">
                    <strong>RECLAMO</strong>: Disconformidad relacionada a los productos o servicios.
                </div>
                <div style="padding:.5rem 1rem;font-size: .9rem; width: 50%; height: 100%;">
                    <strong>QUEJA</strong>: Disconformidad no relacionada a los productos o servicios;  malestar o descontento respecto a la atención al público.
                </div>
            </div>
            <div style="width:100%;height:2.4rem;border:1px solid;border-top: 0;padding: .5rem 1rem;">
                <span style="font-size:.9rem;">Destinatario(consumidor, proveedor o INDECOPI según corresponda)</span>
            </div>
            <p style="font-size: .8rem;">
                * La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI.
            </p>
            <p style="font-size: .8rem;">
                * El proveedor debe dar respuesta al reclamo o queja en un plazo no mayor a quince (15) días hábiles, el cual es improrrogable.
            </p>
            <!--
            <div class="imgs" style="display: flex; align-items:center;justify-content:flex-end">
                <img width="230px" src="indecopi.png">
                <img width="200px" src="indecopi-firma-digital.png">
            </div>
            -->
        </div>
    </body>
    </html>`;
};
