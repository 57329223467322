import React, { Component } from 'react'
import Swal from 'sweetalert2'
import { HomeValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import { checkableBoolProps, FormContainer, setInputProps } from '../common/forms/Form'
import { InterestedEmail } from '../common/Mails/InterestedEmail'
import { MailService } from '../common/services/MailService'
import "./whatsapp.scss"
import $ from 'jquery'
import Data from '../../data/data'
export default class Whatsapp extends Component {
    constructor(props){
        super(props)
        this.mailService = new MailService()
        this.submitForm = this.submitForm.bind(this)
        this.send = this.send.bind(this)
        this.moveScroll = this.moveScroll.bind(this)
        this.change = this.change.bind(this)
        this.state = {
            cont:0,
            hide:false,
            toggle:false,
            message:false,
            startValid: false,
            startValidPhone:false,
            startValidEmail:false,
            fname: '',
            lname: '',
            phone: '',
            email: '',
        }
    }
    componentDidMount(){
        window.addEventListener("scroll",this.moveScroll);
        console.log(this.props.projects)
        // console.log("this whatsapp",this.props)
    }
    change(e){
        if(e.target.dataset.toggle == "btn"){
            this.setState({toggle: !this.state.toggle})
        }
    }
    moveScroll = () =>{
       
        // if(document.querySelector("#form-whatsapp")){
        //     if(window.innerWidth < 500){
        //         if(this.props.active ==  true && this.state.cont == 0){
        //             let time = setInterval(() => {
        //                 if($("#form-whatsapp").offset().top > $(".footer").offset().top - 1){
        //                    this.setState({toggle:true,cont:1})
        //                 }
        //                 clearInterval(time)
        //             }, 8000);
        //         }
        //     }else{
        //         if(this.props.active ==  true && this.state.cont == 0){
        //             if($("#form-whatsapp").offset().top > $(".footer").offset().top - 1){
        //                 this.setState({toggle:true,cont:1})
        //             }
        //         }
        //     }
        // }
    }
    send(e){

    }
    setStateFromInput = (event) =>{

    }
    setStateFromCheckbox = (event) =>{
        
    }
    showModal = ()=>{}
    submitForm(context){
        this.showModal = context.showModal
        return (values, {setSubmitting, resetForm})=> {
            const project = this.props.projects.find(p=>p.title === values.project)

            // if (project.crm.from_mail_wsp){
            //     sessionStorage.setItem("seller_phone", project.crm.from_mail_wsp[Math.floor(Math.random() * project.crm.from_mail_wsp.length)])
            // }

            // const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")

            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
            
            new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))
                
            const axios = require("axios")
            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "14REPqa0jWd-zIjm6mubfty4spw2N7143ufvg0zV_lAY",
                "range": "Mirano Web!A:XX",
                "values": [[date, values.fname, values.lname, values.telephone, values.email, values.project == "PRÓXIMAMENTE" ? "Litoral 900" : values.project, "", "",
                            values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]]
            })

            // this.mailService.from(`Web <${fromEmail}>`)
            //     .to(project.evoltaEmail)
            //     .subject("Evolta lead")
            //     .html(InterestedEmail(values))

            let promise
            // if(project.crm.id === 1536){
                promise = axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
                    "environment": "mirano",
                    "lead": {
                        "IdProyecto": project.crm.id,
                        "Nombres": values.fname,
                        "Apellidos": values.lname,
                        "Correo": values.email,
                        "Celular": values.telephone,
                        "Comentario": values.observation,
                        ...project.crm.id !== 1536 && {"IdTipoPortal": 10},
                        "url_query": localStorage.getItem('url_query')
                    }
                })
            // } else {
            //     promise = this.mailService.send()
            // }

            promise.then(r=>{
                this.showModal(values.project)
                console.log(project)
                window.fbq && window.fbq('track', 'Lead', {project: values.project})
                window.dataLayer && project.adsEvent && window.dataLayer.push({'event': project.adsEvent})
                window.dataLayer && window.dataLayer.push({
                    'event': 'LeadCreated',
                    'source': 'WhatsApp',
                    'project': values.project,
                })
                resetForm()
                document.querySelector("#form-whatsapp").reset()
                project.crm.from_mail_wsp && window.setGeneralSellerPhones()
            })
            .catch(e=>{
                console.error(e)
                Swal.fire({
                    title: '',
                    text: '¡Error! Intentelo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            })
            .finally(() => setSubmitting(false))

        }
    }

    initialValues = {
        fname: null,
        lname: null,
        email: null,
        telephone: null,
        project: this.props.project ? this.props.project.name : null,
        terms: true,
    }
    render() {
        const {projects, project} = this.props
        return (
            <Data.Consumer>
            {context  =>(
                <FormContainer
                initialValues={this.initialValues}
                validationSchema={HomeValidatonSchema}
                onSubmit={this.submitForm(context)}>
                {form => {const {handleSubmit, errors, touched, isSubmitting} = form;
                return(
                <form  className={`content-whatsapp ${this.state.hide ? "hide" : ""}`} onSubmit={handleSubmit} onClick={this.change.bind(this)} id="form-whatsapp">
                    <div className={"content-icon-toggle "+(this.state.toggle ?  "active" : "")} data-toggle="btn">
                        <div className="content-icon" data-toggle="btn">
                            <div className={"icon icon-whatsapp"}>
                                <img src={require("../../assets/images/icons/Whatsapp.png")}></img>
                            </div>
                        </div>
                    </div>
                    <div className={`screen-movil ${(this.state.toggle) ? "active":""}`}>
                        <div className={`content-form ${(this.state.message) ? "message " : ""}`+ (this.state.toggle ? "active " : "") }>
                        <div className="formulario">
                            <h2 className="title">COTIZA TU NUEVO DEPA AQUÍ</h2>
                            <div className="content-input">
                                <input
                                    {...setInputProps("fname", "input", form)}
                                    autocomplete="off"
                                    placeholder="NOMBRE*"
                                ></input>
                            </div>
                            <div className="content-input">
                                <input
                                    {...setInputProps("lname", "input", form)}
                                    autocomplete="off"
                                    placeholder="APELLIDO*"
                                ></input>
                            </div>
                            <div className="content-input">
                                <input
                                    {...setInputProps("telephone", "input", form)}
                                    type="tel"
                                    autocomplete="off"
                                    placeholder="TELÉFONO*"
                                ></input>
                            </div>
                            <div className="content-input">
                                <input
                                    {...setInputProps("email", "input", form)}
                                    type="email"
                                    autocomplete="off"
                                    placeholder="E-MAIL*"
                                ></input>
                            </div>
                            <div className="content-input">
                                <select {...setInputProps("project", "", form)}>
                                    <option value="">PROYECTO DE INTERÉS</option>
                                {projects.map((item, index)=>{
                                    console.log('projects',item)
                                    if(item?.title.includes('MONTE') || item?.title.includes('SAN')){
                                        return null
                                    }
                                    return <option value={item.title} selected={item.title === project?.name}>{item.title} - {item.distrito}</option>
                                }   
                                )}
                                </select>
                            </div>
                        
                            <div className="content-input terms">
                                <input
                                    {...checkableBoolProps("terms", "checkbox", form)}
                                    type="checkbox"
                                    id="term"
                                    checked>
                                </input>
                                <label className={"text-term"} htmlFor="term">Acepto las <strong><a target="_blank" href="/Mirano-Politicas-de-privacidad.pdf">politicas de privacidad</a></strong></label>
                            </div>
                            <div className="content-input">
                                <button type="submit" className="contactar" disabled={isSubmitting}>{isSubmitting ? 'Enviando' : 'Contactar'}</button>
                            </div>
                        </div>
                        <div className="message-whatsapp">
                            <span className="title">
                                Comunícate con un asesor aquí
                            </span>
                            <a href={`https://wa.me/${this.state.seller_phone}`} target="_blank" className="content-icon-message">
                                <div className="icon icon-whatsapp">

                                </div>
                            </a>
                        </div>
                    </div>
                    </div>
                </form>
                )}}
                </FormContainer>
                )}
            </Data.Consumer>
        )
    }
}
