import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import Whatsapp from '../../component/whatsapp/whatsapp';
import dataInicio from '../../data/inicio';
import './nosotros.scss';
import { Helmet } from 'react-helmet';

export default class Nosotros extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        // document.querySelector("nav").classList.remove("active")
    }
	render() {
		return (
			<section class='container-contact'>
				<Whatsapp projects={dataInicio.proyectos} />
				<Helmet>
					<title>{'Mirano Inmobiliaria | Nosotros '}</title>
					<meta
						name='description'
						content='En Mirano Inmobiliaria mantenemos el equilibrio entre la modernidad y exclusividad. Encuentra el departamento perfecto en Barranco, Chacarilla, Miraflores y Lince.'
					></meta>
					<meta
						name='keywords'
						content='inmobiliaria, inmobiliaria en peru, inmobiliaria en lima, inmobiliaria peruana, mirano, mirano inmobiliaria, proyecto inmobiliario, departamentos en venta, venta de departamentos, departamentos en venta en lima, venta de departamentos en lima, departamentos en lima, departamentos en venta en barranco, venta de departamentos en barranco, departamentos en barranco, departamentos en venta en miraflores, venta de departamentos en miraflores, departamentos en miraflores, departamentos en venta en chacarilla, venta de departamentos en chacarilla, departamentos en chacarilla, departamentos en venta en lince, venta de departamentos en lince, departamentos en lince, departamentos en construcción, departamentos en entrega inmediata, departamentos en preventa, proyecto en construcción, proyecto en entrega inmediata, proyecto en preventa'
					></meta>
				</Helmet>
				<div class='container-form '>
                    <img className='bg-us' src={require('../../assets/images/nosotros/Fondo_img_nosotros.jpg')} ></img>
                    <div className='container content-paragraph'>
                        <div class='container-description'>
                            <div class='container-description-width'>
                                <p class='container-description-width-text-form'>
                                    Nos basamos en los valores del diseño contemporáneo y nuestra práctica celebra el compromiso que tenemos con los principios de espacio, luz y distribución.
                                </p>
                                <p class='container-description-width-text-form'>
                                    En Mirano creemos fielmente que desarrollar espacios de vida es un verdadero privilegio, pero también una gran responsabilidad.{' '}
                                </p>
                                <p class='container-description-width-text-form'>
                                    Este enfoque humanista parte de adentro hacia afuera, elevando la arquitectura a un nuevo nivel de conexión entre personas y espacios habitables.
                                </p>
                            </div>
                        </div>
                        <div class='container-flex container-phrase'>
                            <div class='container-content'>
                                <div class='container-content-one'>
                                    <p class='container-content-one-text-form'>
                                        Mirano <br />{' '}
                                        <span class='container-content-one-text-form-aqua'>
                                            {' '}
                                            una vida <br /> Icónica
                                        </span>{' '}
                                        <br /> Inmobiliaria
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container relative content-photo'>
    					<img class='img-circle-us' src={require('../../assets/images/nosotros/tudela-y-varela-445.png')}></img>
                    </div>

					<svg class='svg-contact' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
						<path fill='#ffffff' fill-opacity='1' d='M0,128L1440,320L1440,320L0,320Z'></path>
					</svg>
				</div>
                <div className='w-full'>
                    <div className='container'>
                        <div class='container-information'>
                            <div class='container-flex'>
                                <div>
                                    <p class='container-text'>15</p>
                                    <p class='container-info'>
                                        proyectos <br /> desarrollados
                                    </p>
                                </div>
                                <div>
                                    <p class='container-text'>13</p>
                                    <p class='container-info'>
                                        años de presencia <br /> en el mercado{' '}
                                    </p>
                                </div>
                                <div>
                                    <p class='container-text'>350</p>
                                    <p class='container-info'>
                                        familias viviendo <br /> la experiencia mirano
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</section>
		);
	}
}
