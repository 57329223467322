import { Helmet } from "react-helmet"
import './gracias.scss'
export const Gracias = ({project}) => {
    return(
        <div className="gracias">
            <Helmet>
                <title>{"Gracias por registrarte"}</title>
                <meta name="description" content={"Gracias por registrarte"}/>
            </Helmet>
            <div className='banner'>
                <img className='' src={require('../../assets/images/icons/compress-banner.webp')} ></img>
            </div>
            <div className='container'>
                <div className='c-primary'>
                    <h1 className="title SF-Pro-Display-Regular">
                        ¡Bienvenido!
                    </h1>
                </div>
                <span className='paragraph c-primary'>
                    En breve nuestro asesor se contactará contigo para brindarte toda la información.
                </span>

                <span className='paragraph-wpp c-primary'>También puedes contactarlo directamente al</span>
                <a href="https://api.whatsapp.com/send?phone=51966764536&text=Hola%2C%20deseo%20mayor%20informaci%C3%B3n%20del%20nuevo%20proyecto%20en%20Miraflores" target="_blank" className='btn-whatsapp b-primary'>
                    <img className='icon-wpp' src={require('../../assets/images/icons/btn-wpp.svg').default} ></img>
                    966 764 536
                </a>
            </div>
        </div>
    )
}    