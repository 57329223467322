const copiaSanmatin = require("../../assets/images/brochure/san-martin/CRI Barranco.pdf")
const fichaRucSanmartin = require("../../assets/images/brochure/san-martin/Ficha RUC Vasto.pdf")
const licenciaSanmartin = require("../../assets/images/brochure/san-martin/Licencia-sanmartin.pdf")
const vigenciaSanmartin = require("../../assets/images/brochure/san-martin/Vigencia RBZ - Vasto.pdf")

const data = [
    {
        id:'san-martin-230',
        empresa: 'Inversiones Vasto Sac',
        ruc:20604452202,
        partida: 'Partida Electrónica N°14247654 del Registro de Personas Jurídicas de Lima',
        copialiteral:{pdf:copiaSanmatin,title:'Copia literal de Vasto'},
        vigenciadepoder:{pdf:vigenciaSanmartin,title:"Vigencia de Poder Vasto"},
        ficharuc:{pdf:fichaRucSanmartin,title:"Ficha Ruc Vasto"},
        nombredelproyecto:'San Martín 230',
        direccion:'Calle San Martín 230, Barranco.',
        cantidadunidades:"53 departamentos. Este número podrá cambiar en el transcurso del desarrollo del proyecto, conforme al diseño y a la normativa vigente",
        expediente:'N° 3505-2021',
        partida:"N° 11070649",
        licencia:licenciaSanmartin,
        propiedadexclusiva:[
            'Departamentos de 1-2 dormitorios desde 30.71 m²',
            'Flats y Dúplex con terraza, jardín o balcones',
            'Estacionamiento simples',
            'Depósitos desde 3m²',
        ],
        propiedadcomun:[
            'Lobby',
            'Ascensor',
            'Patio Central',
            'Co Working – Con Patio',
        ],
        dormitorio:[
            'Pisos: Gres porcelánico',
            'Paredes: Papel mural vinílico y moldura decorativa',
            'Mamparas: Vidrio laminado incoloro de 8mm con carpintería de aluminio negro con rejilla para ventilación de gas ',
            'Puertas: Puerta Contraplacada de mdf',
            'Vestidores-Closets: Melamine de color con cajonería',
        ],
        baths:[
            'Pisos: Gres porcelánico',
            'Zócalos: Gres porcelánico',
            'Griferías: Mezcladora Monocomando',
            'Inodoro: One Piece color blanco',
        ],
        Kitchenette:[
            'Pisos: Gres porcelánico',
            'Contra zócalo:  Gres porcelánico',
            'Tableros: Cuarzo blanco estelar o similar',
            'Griferías: Mezcladora Monocomando',
            'Lavaderos: Poza de acero inoxidable',
        ],
        link:"https://www.mirano.pe/Barranco/San-Martin-230",
        salaventas:'San Martin 230 Barranco',
    },
    {
        id:'sucre-296',
        empresa: 'Inversiones Forli Sac',
        ruc:20605874151,
        partida: 'Partida Electrónica N° 14449214 del Registro de Personas Jurídicas de Lima.',
        copialiteral:{pdf:require("../../assets/images/brochure/sucre/CRI Sucre.pdf"),title:'Copia literal de Forli'},
        vigenciadepoder:{pdf:require("../../assets/images/brochure/sucre/Vigencia RBZ - FORLI.pdf"),title:"Vigencia de Poder Forli"},
        ficharuc:{pdf:require("../../assets/images/brochure/sucre/Ficha RUC Forli.pdf"),title:"Ficha Ruc Forli"},
        nombredelproyecto:'Sucre 296',
        direccion:' Calle Mariscal Sucre 296, Miraflores',
        cantidadunidades:"17 departamentos. Este número podrá cambiar en el transcurso del desarrollo del proyecto, conforme al diseño y a la normativa vigente",
        expediente:'N° 0325-2021',
        partida:"N° 00708287",
        licencia:require("../../assets/images/brochure/sucre/licencia-sucre.pdf"),
        propiedadexclusiva:[
            'Departamentos de 2 dormitorios desde 111 m²',
            'Flats y Dúplex con terraza, jardín o balcones',
            'Estacionamiento simples y dobles lineales',
            'Depósitos desde 1.57 m2',
        ],
        propiedadcomun:[
            'Lobby',
            'Ascensor',
        ],
        dormitorio:[
            'Pisos: Bambú ',
            'Paredes: Empastadas y pintadas con dos manos de pintura Látex Blanco',
            'Mamparas: Vidrio laminado incoloro de 8mm con carpintería de aluminio negro con rejilla para ventilación de gas ',
            'Puertas: Puerta Contraplacada de mdf',
            'Vestidores-Closets: Melamine de color con cajonería',
        ],
        baths:[
            'Pisos: Porcelánico',
            'Contra sócalos: Porcelanato',
            'Griferías: Mezcladora Monocomando',
            'Inodoro: One Piece color blanco',
        ],
        Kitchenette:[
            'Pisos: Gres porcelánico',
            'Tableros: Cuarzo blanco estelar o similar',
            'Griferías: Mezcladora Monocomando',
            'Lavaderos: Poza de acero inoxidable',
        ],
        link:"https://www.mirano.pe/Miraflores/Sucre-296",
        salaventas:' Calle Monte Umbroso 122 Surco',
    },
    {
        id:'monte-umbroso-122',
        empresa: ' Inversiones Borgo Sac',
        ruc:20605874496,
        partida: 'Partida Electrónica N°14449215 del Registro de Personas Jurídicas de Lima',
        copialiteral:{pdf:require("../../assets/images/brochure/monte-umbroso/CRI Monte Umbroso.pdf"),title:'Copia literal de Borgo'},
        vigenciadepoder:{pdf:require("../../assets/images/brochure/monte-umbroso/Vigencia RBZ - Borgo.pdf"),title:"Vigencia de Poder Borgo"},
        ficharuc:{pdf:require("../../assets/images/brochure/monte-umbroso/Ficha Ruc Borgo.pdf"),title:"Ficha Ruc Borgo"},
        nombredelproyecto:'Monte Umbroso 122',
        direccion:' Calle Monte Umbroso 122 Santiago de Surco',
        cantidadunidades:"13 departamentos. Este número podrá cambiar en el transcurso del desarrollo del proyecto, conforme al diseño y a la normativa vigente.",
        expediente:'N° 1062-2021',
        partida:"N° 44787539",
        licencia:require("../../assets/images/brochure/monte-umbroso/licencia-monteumbroso.pdf"),
        propiedadexclusiva:[
            'Departamentos de 3 dormitorios desde 117 m²',
            'Flats y Dúplex con terraza, jardín o balcones',
            'Estacionamiento simples y dobles lineales',
            'Depósitos desde 3.12 m²',
        ],
        propiedadcomun:[
            'Lobby',
            'Ascensor',
        ],
        dormitorio:[
            'Pisos: Bambú ',
            'Paredes: Empastadas y pintadas con dos manos de pintura Látex Blanco',
            'Mamparas: Vidrio laminado incoloro de 8mm con carpintería de aluminio negro con rejilla para ventilación de gas ',
            'Puertas: Puerta Contraplacada de mdf',
            'Vestidores-Closets: Melamine de color con cajonería',
        ],
        baths:[
            'Pisos: Porcelánico',
            'Zócalos: Cerámico',
            'Griferías: Mezcladora Monocomando',
            'Inodoro: One Piece color blanco',
        ],
        Kitchenette:[
            'Pisos: Gres porcelánico',
            'Tableros: Cuarzo blanco estelar o similar',
            'Griferías: Mezcladora Monocomando',
            'Lavaderos: Poza de acero inoxidable',
        ],
        link:"https://www.mirano.pe/Chacarilla/Monte-Umbroso-122",
        salaventas:'Calle Monte Umbroso 122 Surco.',
    }
]
export default data;