import { useState } from 'react';
import { data } from './data';
import './subitem.scss';
import './textos.scss'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import bg from '../../assets/images/promos/bg-textos-legales.jpg'

export const SidebarSubItems = ({ index, item,func=()=>{},active }) => {
    const handlePosition = (i)=>{
        func((state)=>{
            return state === i ? -1 : i
        })
    }
	return (
		<Accordion expanded={active} square onChange={()=>{handlePosition(index)}} key={'section-' + index}>
			<AccordionSummary aria-controls='panel-content'>
				<Typography>
					<label className={`c-sidebar-nav-title`}>
						<div className='icon-signal-down mask-center'></div>
						<span className='span-title'>{item.title}</span>
						<div className='icon-signal-right' />
					</label>
				</Typography>
			</AccordionSummary>
                <AccordionDetails >
                    <Typography>
                        <div className='c-sidebar-nav-sub-item subsidebar' key={'acordion-' + index}>
                            <span className='span-title'>{item.paragraph}</span>
                        </div>
                    </Typography>
                </AccordionDetails>
		</Accordion>
	);
};

export const TextoLegales = ()=>{
    const [position, setPosition] = useState(-1);  
    return (
        <main className='textos-legales'>
            <div className='bg-textos'>
                <img src={bg}></img>
                <h1 className="title-text">Textos Legales</h1>
            </div>
            <div className='contain'>
                <div className='ctx'>
                    <h3 className='sub-title-text'>TÉRMINOS Y CONDICIONES DE LA PROMOCIÓN</h3>
                    <div className='w-full'>
                        {
                            data.map((data,index)=>{
                                return (
                                    <div className='content-text'>
                                        <h3 className='title-text'>{data.title}</h3>
                                        <div className='flex flex-col gap-2'>
                                        {
                                            data.texts.map((e,i)=>{
                                                return (
                                                    <SidebarSubItems index={i} item={e} active={position === i} func={setPosition}/>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </main>
    )

}