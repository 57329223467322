import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import Data from '../../data/data'
import './message.scss'
export default class Message extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){

    }
    render(){
        return (
            <Data.Consumer>
                {context  =>(
                <div className={`message ${context.data.message ? "active" : ""}`} data-modal="modal"  onClick={(e)=>{context.change(e)}}>
                    <div className='contain-message'>
                        <div className='title-message'>
                            ¡MUCHAS GRACIAS!
                        </div>
                        <div className='content'>
                            <span className='title-message-2 text-center'>Tus datos ya fueron registrados</span>
                            <p className="paragraph paragraph-1">estás un paso más cerca de tu  <br/>nuevo departamento.</p>
                            <p className="paragraph">Puedes comunicarte con un asesor de ventas aquí:</p>
                            <div className='buttons'>
                            <MediaQuery query="(min-width: 850px)">
                                <a id="phoneNumber" href={`https://wa.me/51${context.data.phone}?text=Hola, quiero información acerca de ${context.data.project}`} target="_blank" className='button b-green c-white'>
                                    <div className='icon whatsapp'></div>
                                    <span className='text'>WhatsApp</span>
                                </a>
                            </MediaQuery>
                            <MediaQuery query="(max-width: 850px)">
                                <a id="phoneNumber" href={`https://api.whatsapp.com/send/?phone=51${context.data.phone}&text=Hola, quiero información acerca de ${context.data.project}`} target="_blank" className='button b-green c-white'>
                                    <div className='icon whatsapp'></div>
                                    <span className='text'>WhatsApp</span>
                                </a>
                            </MediaQuery>
                                <a href={`tel:${context.data.phone}`} target="_blank" className='button b-primary c-white'>
                                    <div className='icon phone'></div>
                                    {context.data.phone}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </Data.Consumer>
        )
    }
}