import React, { Component } from 'react'
import Slider  from 'react-slick'
import $ from 'jquery'
import { Link } from 'react-router-dom'
export default class SliderMain extends Component {
    constructor(props){
        super(props)
        this.afterChange = this.afterChange.bind(this)
        this.moveTo = this.moveTo.bind(this)
        this.state = {
            activeSlider:0,
            map: ``,
            settings:{
                dots: true,
                arrows:true,
                infinite: true,
                speed: 500,
                pauseOnHover:false,
                autoplay: false,
                autoplaySpeed: 6000,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                afterChange:this.afterChange,
                
            },
        }
    }
    moveTo(from,to){
       
        $('html, body').animate({
            scrollTop: $(".content-formulario-all").offset().top - 100
        }, 700);
        
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    componentDidMount(){
        if(window.innerWidth < 500){
            this.setState({map:``})
        }
    }
    render(){
        return (
            <div className="Slider-diap">
                <Slider {...this.state.settings} ref={slider => (this.slider = slider)}>
                    {
                        this.props.data.map((item,index)=>{
                            return(
                            <a {...item.link && {href: item.link}} className="estirar" id={`slide-${index}`}>
                                <div className="background">
                                    <picture >
                                        <source alt="fachada" className="img-background" media="(min-width: 500px)" srcSet={item.imgDesktop}></source>
                                        <img lowsrc alt="fachada" className="img-background " src={item.img} useMap="#image-map"></img>
                                        {
                                            item.svg == true ? 
                                            <>
                                                
                                                <a href="https://litoral900.pe/" className='btn-project btn-litoral'></a> 
                                                <a href="/Chacarilla/Monte-Umbroso-122" className='btn-project btn-monteumbroso'></a> 
                                                {/* <a href="/Miraflores/Sucre-296"  className='btn-project btn-sucre'></a> 
                                                <a href="/Barranco/San-Martin-230" className='btn-project btn-sanmartin'></a>  */}
                                            </>
                                            : null
                                        }
                                    </picture>
                                </div>
                                {
                                    item.paragraph ? 
                                    <div className={`content-text ${"content-text-"+index}`} dangerouslySetInnerHTML={{__html:item.paragraph}}></div>
                                    :null
                                }
                                
                                {
                                    item.move ? 
                                    <a className="btn-move" href={this.props.utm}>
                                        
                                    </a> : null
                                }
                            </a>
                            )
                        })
                    }
                </Slider>
            </div>
        )
    }
}