import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { ContactValidationSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { checkableBoolProps, FormContainer, setInputProps } from '../../component/common/forms/Form';
import { InterestedEmail } from '../../component/common/Mails/InterestedEmail';
import { MailService } from '../../component/common/services/MailService';
import './contacto.scss'
import { Helmet } from 'react-helmet';
export default class Form extends Component {
  constructor(props){
    super(props)
    this.mailService = new MailService()
    this.submitForm = this.submitForm.bind(this)
  }
  
  submitForm(values, {setSubmitting, resetForm}){
    const {data: {proyectos}} = this.props
    const project = proyectos.find(p=>p.title === values.project)


    // if (project.crm.from_mail_wsp){
    //     sessionStorage.setItem("seller_phone", project.crm.from_mail_wsp[Math.floor(Math.random() * project.crm.from_mail_wsp.length)])
    // }

    // const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")

    const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
    
    new URLSearchParams(localStorage.getItem('url_query'))
      .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))

    const axios = require("axios")
    axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
        "ss_id": "14REPqa0jWd-zIjm6mubfty4spw2N7143ufvg0zV_lAY",
        "range": "Mirano Web!A:XX",
        "values": [[date, values.fname, values.lname, values.telephone, values.email, values.project == "PRÓXIMAMENTE" ? "Litoral 900" : values.project, "", "",
                    values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]]
    })

    values.observation = [
        `Motivo: visita guiada por los planos`,
        values.message && `Mensáje: ${values.message}`,
        values.medium && `Medio: ${values.medium}`,
        values.project == "PRÓXIMAMENTE" && `Proyecto: Litoral 900`
    ].filter(Boolean).join(", ")

    // this.mailService.from(`Web <${fromEmail}>`)
    //     .to(project.evoltaEmail)
    //     .subject("Evolta lead")
    //     .html(InterestedEmail(values))
        
    let promise
    // if(project.crm.id === 1536){
        promise = axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
            "environment": "mirano",
            "lead": {
                "IdProyecto": project.crm.id,
                "Nombres": values.fname,
                "Apellidos": values.lname,
                "Correo": values.email,
                "Celular": values.telephone,
                "Comentario": values.observation,
                ...project.crm.id !== 1536 && {"IdTipoPortal": 10},
                "url_query": localStorage.getItem('url_query')
            }
        })
    // } else {
    //     promise = this.mailService.send()
    // }

    promise.then(r=>{
        window.dataLayer && project.adsEvent && window.dataLayer.push({'event': project.adsEvent})
        window.dataLayer && window.dataLayer.push({
            'event': 'LeadCreated',
            'source': 'Form',
            'project': values.project,
        })
        window.fbq && window.fbq('track', 'Lead', {project: values.project})
        resetForm()
        // console.log(values)
        document.querySelector("form").reset()
        project.crm.from_mail_wsp && window.setGeneralSellerPhones()
        Swal.fire({
            title: '',
            text: 'Datos enviados',
            icon: 'success',
            confirmButtonText: 'Cerrar',
            confirmButtonColor: 'var(--primary)'
        })
    })
    .catch(e=>{
        console.error(e)
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red'
        })
    })
    .finally(() => setSubmitting(false))
  }

  initialValues = {
    fname: null,
    lname: null,
    email: null,
    telephone: null,
    message: null,
    medium: null,
    project: null,
    terms: false,
  }
  render() {
    return (
      <section class="container-formulario">
        <Helmet>
            <title>{"Mirano Inmobiliaria | Contacto"}</title>
            <meta  name="description" content="En Mirano Inmobiliaria mantenemos el equilibrio entre la modernidad y exclusividad. Encuentra el departamento perfecto en Barranco, Chacarilla, Miraflores y Lince."></meta>
            <meta  name="keywords" content="inmobiliaria, inmobiliaria en peru, inmobiliaria en lima, inmobiliaria peruana, mirano, mirano inmobiliaria, proyecto inmobiliario, departamentos en venta, venta de departamentos, departamentos en venta en lima, venta de departamentos en lima, departamentos en lima, departamentos en venta en barranco, venta de departamentos en barranco, departamentos en barranco, departamentos en venta en miraflores, venta de departamentos en miraflores, departamentos en miraflores, departamentos en venta en chacarilla, venta de departamentos en chacarilla, departamentos en chacarilla, departamentos en venta en lince, venta de departamentos en lince, departamentos en lince, departamentos en construcción, departamentos en entrega inmediata, departamentos en preventa, proyecto en construcción, proyecto en entrega inmediata, proyecto en preventa"></meta>
        </Helmet>
            <div class="container-flex">
              <div class="information">
                <h2 class="information_title">Nos encantaría ofrecerte una visita guiada por los planos de nuestros proyectos</h2>
                <p class="information_paragraph">Puedes acercarte a nuestra sala de ventas o también puedes solicitar una videollamada para que un asesor te haga un recorrido virtual.</p>
              </div>
              <FormContainer
                  initialValues={this.initialValues}
                  validationSchema={ContactValidationSchema}
                  onSubmit={this.submitForm}>
                  {form => {const {handleSubmit, values, errors, touched, isSubmitting} = form;
                  console.log(errors)
                  return(
                    <form class="form" onSubmit={handleSubmit}>
                      <div class="form_content">
                        <p class="form_content_paragraph">Por favor, llena el siguiente formulario y pronto estaremos contigo</p>
                      </div>
                      <div class="form_register">
                          <input {...setInputProps("fname", "form_register_input", form)} placeholder="NOMBRE*"></input>
                          <input {...setInputProps("lname", "form_register_input", form)} placeholder="APELLIDO*"></input>
                          <input {...setInputProps("email", "form_register_input", form)} placeholder="E-MAIL*"></input>
                          <input {...setInputProps("telephone", "form_register_input", form)} placeholder="TELÉFONO*"></input>
                          <select {...setInputProps("project", "form_register_input", form)}>
                              <option value="">PROYECTO DE INTERÉS*</option>
                              {this.props.data.proyectos.map((item, index)=>
                                  <option value={item.title}>{item.title} - {item.distrito}</option>
                              )}
                          </select>
                          <input {...setInputProps("message", "form_register_input", form)} placeholder="¿TIENES ALGUNA PREGUNTA O COMENTARIO?*"></input>
                      </div>
                      
                      <div class="form_send">
                        <div className='d-flex'>
                            <p class="form_send_paragraph">Escoge la plataforma de tu preferencia:</p>
                            <div class="check_opcion">
                                <div class="form_content">
                                  <input type="radio" value="Zoom" {...setInputProps("medium", "form_content_checkbox", form)}></input>
                                  <label class="form_content_opcion">Zoom</label>
                                </div>
                                <div class="radio">
                                  <input type="radio" value="Google Meet" {...setInputProps("medium", "form_content_checkbox", form)}></input>
                                  <label class="form_content_opcion">Google Meet</label>
                                </div>
                            </div>
                        </div>
                          <div class="content-input terms">
                              <input id="term" type="checkbox" {...checkableBoolProps("terms", "checkbox", form)}/>
                              <label class="text-term" for="term">He leído y acepto la&nbsp;<strong>
                                <a target="_blank" href="/Mirano-Politicas-de-privacidad.pdf">política de datos personales</a></strong>
                              </label>
                          </div>
                      </div>
                          <button type="submit" class="form_send_button" disabled={isSubmitting}>{isSubmitting ? 'ENVIANDO' : 'ENVIAR'}</button>
                    </form>)}}
            </FormContainer>
          </div>
        </section>
    );
  }
}
