import { PROJECTS } from "./data"
import sucre296 from './sucre-296'
import sanMartin from './san-martin'
import monteUmbroso from './montent-humbroso'
import pasteur1228 from './pasteur-1228'
import pasteur1250 from './pasteur-1250'
import villavicencio from './villavicencio-1320'
import tudelayvarela from './tudela-y-varela-445'
import Litoral900 from './litoral900'

export const projects_data = new Map([
    [PROJECTS.sm230, sanMartin],
    [PROJECTS.mu122, monteUmbroso],
    [PROJECTS.s296, sucre296],
    [PROJECTS.tyv445, tudelayvarela],
    [PROJECTS.p1228, pasteur1228],
    [PROJECTS.p1250, pasteur1250],
    [PROJECTS.v1320, villavicencio],
    [PROJECTS.l900, Litoral900],
])