import React, { Component } from 'react';
import './modal.scss'
export default class Modal extends Component {
    constructor(props){
        super(props)
        this.state = {
            active:false
        }
    }
    componentDidMount(){

    }
    render() {
        return (
            <div className={`modal`}>
                 {this.props.children}
            </div>
        );
    }
}
