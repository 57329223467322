import React, { Component } from 'react';
import OwlCarousel from "react-owl-carousel2"
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import $ from 'jquery'
const btnLeft = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62"><defs><style>.cls-1{fill:#17255d;}</style></defs><title>Recurso 2laptop</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M37.87,14.72a1.26,1.26,0,0,0-.37-.9,1.32,1.32,0,0,0-1.81,0L19.41,30.09A1.3,1.3,0,0,0,19,31a1.26,1.26,0,0,0,.37.9L35.69,48.18a1.28,1.28,0,0,0,1.81-1.81L22.13,31,37.5,15.63A1.27,1.27,0,0,0,37.87,14.72Z"/><path class="cls-1" d="M31,0A31,31,0,1,0,62,31,31,31,0,0,0,31,0ZM59.44,31A28.44,28.44,0,1,1,31,2.56,28.47,28.47,0,0,1,59.44,31Z"/></g></g></svg>';
const btnRight = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 62"><defs><style>.cls-1{fill:#17255d;}</style></defs><title>Recurso 3laptop</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path class="cls-1" d="M26.31,13.82a1.32,1.32,0,0,0-1.81,0,1.29,1.29,0,0,0,0,1.81L39.87,31,24.5,46.37a1.28,1.28,0,0,0,1.81,1.81L42.59,31.9A1.26,1.26,0,0,0,43,31a1.3,1.3,0,0,0-.37-.91Z"/><path class="cls-1" d="M31,0A31,31,0,1,0,62,31,31,31,0,0,0,31,0ZM59.44,31A28.44,28.44,0,1,1,31,2.56,28.47,28.47,0,0,1,59.44,31Z"/></g></g></svg>'


export default class Proyectos extends Component {
    constructor(props){
        super(props)
        this.changes = this.changes.bind(this)
        this.state = {
            index:0,
            cards:this.props.data.proyectos,
            events:{onDragged:this.onDragged,onChanged:function(item){}},
            options:{
                rewind: true,
                loop:false,
                nav:false,
                center:false,
                autoWidth:false,
                speed:1000,
                smartSpeed:300,
                dots:false,
                items:1,
                startPosition:0,
                rewind:false,
                slideBy:1,
            }
        }
    }
    changes(e){
        let target = e.target.value
        let filtered = this.props.data.proyectos.filter((e)=>{
            if(target == "all"){
                return e
            }else if(e.distrito == target){
                return e
            }
        })
        this.setState({cards:filtered})
    }
    onDragged = (e)=>{
        console.log("move",e.item.index)
        $(".content-text-all .title-card").text(this.props.data.proyectos[e.item.index].title)
        $(".content-text-all .sub-title").text(this.props.data.proyectos[e.item.index].distrito)
        
        $(".content-balls .item").removeClass("active")
        document.querySelector(`.item-ball-${e.item.index}`).classList.add("active")
    }
    btnLeftSlider = () => {
        let index = this.sliderCarousel.currentPosition
        console.log("slide:",index)
        try{
            $(".content-text-all .title-card").text(this.props.data.proyectos[index-1].title)
            $(".content-text-all .sub-title").text(this.props.data.proyectos[index-1].distrito)
        }catch(err){
            console.error(err)
        }
        if(document.querySelector(`.item-ball-${index-1}`)){
            $(".content-balls .item").removeClass("active")
            document.querySelector(`.item-ball-${index-1}`).classList.add("active")
        }
        
    }
    btnRightSlider = (e) => {
        let index = this.sliderCarousel.currentPosition
        console.log("slide:",index)
        try{
            $(".content-text-all .title-card").text(this.props.data.proyectos[index+1].title)
            $(".content-text-all .sub-title").text(this.props.data.proyectos[index+1].distrito)
        }catch(err){
            console.error(err)
        }
        
        if(document.querySelector(`.item-ball-${index+1}`)){
            $(".content-balls .item").removeClass("active")
            document.querySelector(`.item-ball-${index+1}`).classList.add("active")
        }
    }

    render() {
    return (
        <div className={`template inicio ${this.props.showTitle == true ? "active" : "none"}`}>
            {/* <h2 className={`text-center c-primary Display-Thin ${this.props.showTitle == true ? "active" : "none"}`} >PROYECTOS <span className='Pro-Italic'>EXCLUSIVOS</span>  EN VENTA</h2> */}
            {/* <div className='form'>
                <select className={`selector ${this.props.showTitle == true ? "active" : "none"}`} onChange={this.changes}>
                    <option value='all'>SELECCIONAR</option>
                    <option value={"BARRANCO"}>BARRANCO</option>
                    <option value={"CHACARILLA"}>CHACARILLA</option>
                    <option value={"MIRAFLORES"}>MIRAFLORES</option>
                    <option value={"LINCE"}>LINCE</option>
                    
                </select>
            </div> */}
            <section className="section proyectos">
                {
                this.props.children
                }
                <h2 className={`title title-horizontal c-primary ${this.props.showTitle == true ? "none" : ""}`}>PROYECTOS</h2>
                <div className={`content-title ${this.props.showTitle == true ? "none" : ""}`}>
                    <h2 className="title-section c-primary">
                        PROYECTOS
                    </h2>
                </div>
                <div className={`content-card ${this.props.showTitle == true ? "active" : ""}`}>
                    <div className='container content-all-cards proyect-inicio'>
                        <MediaQuery query="(min-width: 500px)">
                        {
                            this.state.cards.map((item,index)=>{
                                if(item.link == ''){
                                     return(
                                    <div id={"card-"+index} className='content-card-proyectos' href={item.link ? item.link : undefined} data-name={item.title} style={{cursor:"auto"}}>
                                        <div className="content-text">
                                            <div className='title-text'>{item.title}</div>
                                            <div className='content-brand'>
                                                {item.brand ?
                                                    <img src={item.brand} loading='lazy'/> : null
                                                    }
                                                </div>
                                        </div>
                                        <div class="content-img"><img src={item.img}></img></div>
                                        <div class="content-info">
                                            <div className='w-full'>
                                                <div class="text-info" dangerouslySetInnerHTML={{__html:item.featuresDesk}}></div>
                                                <div class={`text-info bold`}>{item.delivery.length > 0 ? item.delivery : ""}</div>
                                            </div>
                                            {
                                                item.link ? 
                                                <div class="btn-link c-primary">VER MÁS</div> : null
                                            }
                                        </div>
                                    </div>
                                    )
                                }else
                                if(item.link.includes(".php")){
                                    return(
                                    <a id={"card-"+index} className='content-card-proyectos' href={item.link ? item.link : undefined} data-name={item.title}>
                                        <div className="content-text">
                                            <div className='title-text'>{item.title}</div>
                                            <div className='content-brand'>
                                                {item.brand ?
                                                    <img src={item.brand} loading='lazy'/> : null
                                                    }
                                                </div>
                                        </div>
                                        <div class="content-img"><img src={item.img}></img></div>
                                        <div class="content-info">
                                            <div className='w-full'>
                                                <div class="text-info" dangerouslySetInnerHTML={{__html:item.featuresDesk}}></div>
                                                <div class={`text-info bold`}>{item.delivery.length > 0 ? item.delivery : ""}</div>
                                            </div>
                                            {
                                                item.link ? 
                                                <div class="btn-link c-primary">VER MÁS</div> : null
                                            }
                                        </div>
                                    </a>
                                    )
                                }else{
                                    return(
                                        <a id={"card-"+index} className='content-card-proyectos' href={item.link} data-name={item.title}>
                                            <div className="content-text">
                                                <div className='title-text'>{item.title}</div>
                                                <div className='content-brand'>
                                                    {item.brand ?
                                                    <img src={item.brand}></img> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="content-img"><img src={item.img}></img></div>
                                            <div class="content-info">
                                                <div className='w-full'>
                                                    <div class="text-info" dangerouslySetInnerHTML={{__html:item.featuresDesk}}></div>
                                                    <div class={`text-info bold`}>{item.delivery.length > 0 ? item.delivery : ""}</div>
                                                </div>
                                                {
                                                item.link ? 
                                                <div class="btn-link c-primary">VER MÁS</div> : null
                                            }
                                            </div>
                                        </a>
                                        )
                                    }
                                })
                            }
                        </MediaQuery>

                    </div>
                        <MediaQuery query="(max-width: 499px)">
                            <div className='content-text-all'>
                                <div className='content-text'>
                                    <div className='btn-right-slider' onClick={()=>{this.btnLeftSlider(); this.sliderCarousel.prev()}}
                                    dangerouslySetInnerHTML={{__html:btnLeft}}>
                                    </div>
                                    <div className=''>
                                        <h2 className="title-card">{this.props.data.proyectos[this.state.index].title}</h2>
                                        <span className="sub-title">{this.props.data.proyectos[this.state.index].distrito}</span>
                                    </div>
                                    <div className='btn-right-slider' onClick={()=>{this.btnRightSlider(); this.sliderCarousel.next()}}
                                    dangerouslySetInnerHTML={{__html:btnRight}}>
                                    </div>
                                </div>
                                <div className='content-balls'>
                                    {
                                        this.state.cards.map((item,index)=>{
                                            return(
                                                <div className={`item item-ball-${index} ${this.state.index == index ? "active" : ""}`}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <OwlCarousel ref={slider => (this.sliderCarousel = slider)} options={this.state.options} events={this.state.events} className="owl-carousel">
                                {
                                    this.state.cards.map((item,index)=>{
                                        if(item.link.includes(".php")){
                                            return(
                                                <div> 
                                            <div className="card" id={"card-"+index}>
                                                <a href={item.link}>
                                                    <div className="content-img">
                                                        <img src={item.img}></img>
                                                    </div>
                                                </a>
                                                <div className="content-characteristic">
                                                    <h3 className="title-characteristic">CARACTERÍSTICAS</h3>
                                                    <div className="contain">
                                                        <div className="characteristic">
                                                            {
                                                                item.characteristic.map((e)=>{
                                                                    return(
                                                                        <span className="c-primary" dangerouslySetInnerHTML={{__html:e}}>{}</span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="disigner">
                                                            {
                                                                item.entrega.year.length < 1 ?
                                                                null : <span className="c-primary title-disigner">Entrega:</span> 
                                                                
                                                            }
                                                            <span className="c-primary sub-title-disigner">{item.entrega.year}</span>
                                                            {
                                                                item.entrega.disigner ? <>
                                                                <span className="c-primary title-disigner">diseñado por:</span>
                                                                <span className="c-primary sub-title-disigner">{item.entrega.disigner}</span> 
                                                                </>: null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            )
                                        }else{
                                            return(
                                                <div> 
                                                <div className="card" id={"card-"+index}>
                                                    <a href={item.link}>
                                                        <div className="content-img">
                                                            <img src={item.img}></img>
                                                        </div>
                                                    </a>
                                                    <div className="content-characteristic">
                                                        <h3 className="title-characteristic">CARACTERÍSTICAS</h3>
                                                        <div className="contain">
                                                            <div className="characteristic">
                                                                {
                                                                    item.characteristic.map((e)=>{
                                                                        return(
                                                                            <span className="c-primary" dangerouslySetInnerHTML={{__html:e}}></span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="disigner">
                                                                {
                                                                    item.entrega.year.length < 1 ?
                                                                    null : <span className="c-primary title-disigner">Entrega:</span> 
                                                                    
                                                                }
                                                                <span className="c-primary sub-title-disigner">{item.entrega.year}</span>
                                                                 {
                                                                item.entrega.disigner ? <>
                                                                <span className="c-primary title-disigner">diseñado por:</span>
                                                                <span className="c-primary sub-title-disigner">{item.entrega.disigner}</span> 
                                                                </>: null
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                
                                            )
                                        }
                                    }
                                        )
                                    }
                            </OwlCarousel>
                        </MediaQuery>
                    </div>
                </section>
        </div>
    );
  }
}
