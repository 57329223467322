import OwlCarousel from "react-owl-carousel2"
import './Working.scss'
import { useEffect, useState } from "react"
export const Working = () => {
    const [selected, setSelected] = useState(0)
    const data = [
        {
            title: "San martín 230",
            url: "san-martin-230",
            gallery: [
                {
                    month: "Abril",
                    year: "2023",
                    photos: [
                        {
                            img: require("../../assets/images/working/san-martin/avance-de-obra-san-martin-1.jpg")
                        },
                        {
                            img: require("../../assets/images/working/san-martin/avance-de-obra-san-martin-2.jpg")
                        },
                        {
                            img: require("../../assets/images/working/san-martin/avance-de-obra-san-martin-3.jpg")
                        },
                        {
                            img: require("../../assets/images/working/san-martin/avance-de-obra-san-martin-4.jpg")
                        },
                    ]
                },
                {
                    month: "Julio",
                    year: "2023",
                    photos: [
                        {
                            img: require("../../assets/images/inicio/slider/fachada/fachada-san-martin.jpg")
                        },
                        {
                            img: require("../../assets/images/inicio/slider/fachada/slider-2-web.jpg")
                        },
                        {
                            img: require("../../assets/images/inicio/slider/fachada/slider-3.jpg")
                        },
                        {
                            img: require("../../assets/images/inicio/slider/fachada/slider-4.jpg")
                        },
                        {
                            img: require("../../assets/images/inicio/slider/fachada/coworking.jpg")
                        },
                    ]
                },
            ]
        },
        {
            title: "Sucre 296",
            url: "sucre-296",
            gallery: [
                {
                    month: "Abril",
                    year: "2023",
                    photos: [
                        {
                            img: require("../../assets/images/working/sucre/avance-de-obra-sucre-1.jpg")
                        },
                        {
                            img: require("../../assets/images/working/sucre/avance-de-obra-sucre-2.jpg")
                        },
                        {
                            img: require("../../assets/images/working/sucre/avance-de-obra-sucre-4.jpg")
                        },
                        {
                            img: require("../../assets/images/working/sucre/avance-de-obra-sucre-3.jpg")
                        },
                    ]
                },
                {
                    month: "Julio",
                    year: "2023",
                    photos: [
                        {
                            img: require("../../assets/images/working/sucre/slider-1.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-2.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-3.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-4.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-5.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-6.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-7.jpg")
                        },  
                        {
                            img: require("../../assets/images/working/sucre/slider-8.jpg")
                        },  
                    ]
                }
            ]
        }
    ]
    const events={onDragged:function(){},onChanged:function(item){}};
    const optionsSlider = {
        rewind: false,
        loop:false,
        nav:true,
        dots:true,
        center:false,
        // autoWidth:true,
        speed:1000,
        smartSpeed:300,
        items:1,
        slideBy:1,
        margin:0,
        navText: [
            '<svg xmlns="http://www.w3.org/2000/svg" id="Capa_2" data-name="Capa 2" viewBox="0 0 25.77 25.77"><g id="Capa_1-2" data-name="Capa 1"><g><circle cx="12.89" cy="12.89" r="12.89" style="fill: #fff; opacity: .35;"/><path d="m15.48,19.8c-2.34-2.31-4.68-4.61-7.02-6.92l7.02-6.92" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.19px;"/></g></g></svg>',
            '<svg xmlns="http://www.w3.org/2000/svg" id="Capa_2" data-name="Capa 2" viewBox="0 0 25.77 25.77"><g id="Capa_1-2" data-name="Capa 1"><g><circle cx="12.89" cy="12.89" r="12.89" style="fill: #fff; opacity: .35;"/><path d="m10.29,19.8c2.34-2.31,4.68-4.61,7.02-6.92l-7.02-6.92" style="fill: none; stroke: #fff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.19px;"/></g></g></svg>'
        ],
    }
    const onChange = (e) => {
        window.history.pushState(null, null, `?project=${data[e.target.value].url}`);
        setSelected(e.target.value)
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('project');
        if(myParam){
            const index = data.findIndex((item) => item.url === myParam)
            if(index >= 0){
                let select = document.getElementById('projects')
                select.options[index].selected = true
                setSelected(index)
            }
        }
    }, [])
  return (
    <main className="working">
        <div className={`content-title`}>
            <h2 className="title-section c-primary">
                {data[selected].title}
            </h2>
        </div>
        <div className='container'>
            <h1 className="title">AVANCE DE OBRA</h1>
            <div className='flex selector'>
                <span className='sub-title'>SELECCIONA EL PROYECTO</span>
                <select className='select-projects' onChange={onChange} id="projects">
                    {
                        data.map((item, index) => {
                            return (
                                <option key={index} value={index}>{item.title}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className='gallery'>
                {
                    data[selected].gallery.map((item, index) => {
                        return (
                        <div className='work-by-month'>
                            <OwlCarousel options={optionsSlider} events={events} className="owl-carousel">
                                {
                                    item.photos.map((photo, index) => {
                                        return(
                                            <div className='photos'>
                                                <img className='' src={photo.img} ></img>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                            <div className='flex footer-working'>
                                <span className='title-month'>{item.month}</span>
                                <span className='title-year'>{item.year}</span>
                            </div>
                        </div>)
                        
                    })
                }
            </div>
        </div>
    </main>
  )
}