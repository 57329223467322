import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import Swal from 'sweetalert2';
import { HomeValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { checkableBoolProps, FormContainer, setInputProps } from '../../component/common/forms/Form';
import { EventEmail } from '../../component/common/Mails/EventEmail';
import { InterestedEmail } from '../../component/common/Mails/InterestedEmail';
import { MailService } from '../../component/common/services/MailService';
import Data from '../../data/data';
import '../contacto/contacto.scss'
import './promos.scss'

export default class Promos extends Component {
    constructor(props){
        super(props)
        this.submitForm = this.submitForm.bind(this)
    }

    MailClient(){
        return new MailService()
    }

    showModal = ()=>{}

    submitForm(context){
        this.showModal = context.showModal
        return (values, {setSubmitting, resetForm})=> {
            const project = this.props.projects.find(p=>p.title === values.project)

            // if (project.crm.from_mail_wsp){
            //     sessionStorage.setItem("seller_phone", project.crm.from_mail_wsp[Math.floor(Math.random() * project.crm.from_mail_wsp.length)])
            // }

            // const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")
            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

            new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))
    
            const axios = require("axios")
            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "14REPqa0jWd-zIjm6mubfty4spw2N7143ufvg0zV_lAY",
                "range": "Evento web!A:XX",
                "values": [[date, values.fname, values.lname, values.telephone, values.email, values.project == "PRÓXIMAMENTE" ? "Litoral 900" : values.project, "",
                    "", values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term,
                ]]
            })

            // values.observation = "Outlet Wow"
            // values.preheader = "Gracias por registrarte al Outlet Wow. ¡Nos vemos pronto!"

            // this.MailClient().from(`Mirano Inmobiliaria <mkt@mirano.pe>`)
            //     .to(values.email)
            //     .subject("Outlet Wow - Mirano Inmobiliaria")
                // .html(EventEmail(values)).send()
            

            let promise
            // if(project.crm.id === 1536){
                promise = axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
                    "environment": "mirano",
                    "lead": {
                        "IdProyecto": project.crm.id,
                        "Nombres": values.fname,
                        "Apellidos": values.lname,
                        "Correo": values.email,
                        "Celular": values.telephone,
                        "Comentario": values.observation,
                        ...project.crm.id !== 1536 && {"IdTipoPortal": 10},
                        "url_query": localStorage.getItem('url_query')
                    }
                })
            // } else {
            //     promise = this.MailClient().from(`Web <${fromEmail}>`)
            //                                 .to(project.evoltaEmail)
            //                                 .subject("Evolta lead")
            //                                 .html(InterestedEmail(values)).send()
            // }


            promise.then(r=>{
                    this.showModal(values.project)

                    window.dataLayer && window.dataLayer.push({
                        'event': 'LeadCreated',
                        'source': 'Form',
                        'project': values.project,
                    })
                    resetForm()
                    // Swal.fire({
                    //     title: '',
                    //     text: 'Datos enviados',
                    //     icon: 'success',
                    //     confirmButtonText: 'Aceptar',
                    //     confirmButtonColor: '#1019a6'
                    // })
                    document.querySelector("form").reset()
                    project.crm.from_mail_wsp && window.setGeneralSellerPhones()
                })
                .catch(e=>{
                    console.error(e)
                    Swal.fire({
                        title: '',
                        text: '¡Error! Intentelo más tarde',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: 'red'
                    })
                })
                .finally(() => setSubmitting(false))
        }
    }

    initialValues = {
        fname: null,
        lname: null,
        email: null,
        telephone: null,
        project: null,
        terms: false,
    }

    render() {
        return (
            <Data.Consumer>
            {context=>(
                <div className='template'>
                    <section class="container-formulario promos" style={{background:"white"}}>
                        <div class="container-flex container">
                            <div class="information">
                                <div className='content-img' style={{backgroundImage: `url(${require("../../assets/images/promos/Portada_landing.jpg")})`, backgroundSize: "contain"}}>
                                    
                                </div>
                                <MediaQuery query="(min-width:500px)">
                                    <p className='paragraph'>
                                        (*) “Descuentos de hasta S/50,000”: Válido para separaciones realizadas desde el 01/12/2021 hasta el 31/12/2021 en el proyecto Pasteur 1250, Pasteur 1228 y Villavicencio 1320 y cuya firma de minuta de compraventa sea máximo hasta el 15/01/2022. El descuento máximo es aplicado únicamente al departamento 504 de 115 m2 del piso 5 del proyecto Villavicencio 1320. Consultar descuentos de los otros departamentos de nuestro Outlet Wow Mirano con nuestros asesores comerciales. No acumulable con otras promociones. (1) Entrega inmediata será luego de la cancelación total del precio de venta del departamento.
                                    </p>
                                </MediaQuery>
                            </div>
                            <FormContainer
                                initialValues={this.initialValues}
                                validationSchema={HomeValidatonSchema}
                                onSubmit={this.submitForm(context)}>
                                {form => {const {handleSubmit, errors, touched, isSubmitting} = form;
                                return(
                                    <form class="form" onSubmit={handleSubmit}>
                                        <div class="form_content">
                                            <h2 class="information_title c-white">Inscríbete <br/> en el Outlet Wow</h2>
                                        </div>
                                        <div class="form_register">
                                            <input type="text" {...setInputProps("fname", "form_register_input", form)} placeholder="NOMBRE*"></input>
                                            <input type="text" {...setInputProps("lname", "form_register_input", form)} placeholder="APELLIDO*"></input>
                                            <input type="email" {...setInputProps("email", "form_register_input", form)} placeholder="E-MAIL*"></input>
                                            <input type="tel" {...setInputProps("telephone", "form_register_input", form)} placeholder="TELÉFONO*"></input>
                                            <select {...setInputProps("project", "form_register_input", form)}>
                                                <option value="">PROYECTO DE INTERÉS*</option>
                                                <option>VILLAVICENCIO 1320</option>
                                                <option>PASTEUR 1250</option>
                                                <option>PASTEUR 1228</option>
                                            </select>
                                        </div>
                                        
                                        <div class="form_send">
                                            
                                            <div class="content-input terms">
                                                <input id="term" {...checkableBoolProps("terms", "checkbox", form)} type="checkbox"/>
                                                <label class="text-term" for="term">He leído y acepto la&nbsp;<strong>
                                                <a target="_blank" href="/Mirano-Politicas-de-privacidad.pdf">política de datos personales</a></strong>
                                                </label>
                                            </div>
                                        </div>
                                        <button type="submit" class="form_send_button" disabled={isSubmitting}>{isSubmitting ? 'ENVIANDO' : 'ENVIAR'}</button>
                                    </form>)}}
                            </FormContainer>
                        </div>
                            
                    </section>
                    {/* <MediaQuery query="(max-width:499px)">
                        <p className='paragraph-down'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </MediaQuery> */}
                </div>
            )}
        </Data.Consumer>
        );
    }
}
