import React, { Component } from 'react'
import "./contacto.scss"

export default class Contacto extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
        <div className={"contacto "+this.props.page} key={"contacto"}>
            <h2 className="title">CONTÁCTANOS</h2>
            <form className="formulario" >
                <div className="content-input col">
                    <span className="title-input">Nombre*</span>
                    <input type="text" className="input"></input>
                </div>
                <div className="content-input col">
                    <span className="title-input">Apellido*</span>
                    <input type="text" className="input"></input>
                </div>
                <div className="content-input large">
                    <span className="title-input">Email*</span>
                    <input type="text" className="input"></input>
                </div>
                <div className="content-input large">
                    <span className="title-input">Teléfono*</span>
                    <input type="text" className="input"></input>
                </div>
                <div className="content-input large">
                    <span className="title-input">Número de dormitorios*</span>
                    <select className="input col">
                        <option selected disabled value ="1">1 Dormitorio desde USD188,235</option>
                        <option value="2">1 Dormitorio desde USD188,235</option>
                        <option value="3">2 Dormitorios desde USD188,235</option>
                    </select>
                </div>
                <div className="content-input large">
                    <span className="title-input">Número de dormitorios*</span>
                    <select className="input col">
                        <option selected disabled value ="1">Horario de contacto*</option>
                        <option value="2">9:00 am - 11:00am</option>
                    </select>
                </div>
                <div className="btn"><button type="submit" className="btn-submit btn-cotizar">ENVIAR </button></div>
                <div className="content-input terms">
                    <input
                        className="checkbox"
                        type="checkbox"
                        id="term"
                        name="accepted"
                        checked={this.state.accepted}>
                    </input>
                    <label className={"text-term"} htmlFor="term">He leído y acepto la<strong><a target="_blank" href="/politica-de-datos.pdf"> política de datos personales</a></strong></label>
                </div>
            </form>
        </div>
        )
    }
}
