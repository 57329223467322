import React from 'react'
import { Formik } from 'formik'

export const FormContainer = 
({initialValues, validationSchema, onSubmit, children}) => (
    <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {children}
    </Formik>
)

export default function validate(getValidationSchema) {
    return (values) => {
        const validationSchema = getValidationSchema(values)
        try {
            validationSchema.validateSync(values, {abortEarly: false})
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
}

export const handleChecked = (e, form) => {
    form.setFieldValue(e.target.name, e.target.checked)
}

export const setInputProps =(name, classes = "", {values, errors, touched, handleChange, handleBlur}) => ({
    name: name,
    value: values[name],
    className: `${classes} ${errors[name] && touched[name] ? "--invalid" : ""}`,
    onChange: handleChange,
    onBlur: handleBlur
})

export const setCurrencyInputProps = (name, classes = "", {errors, touched, handleChange, handleBlur, setFieldValue}) => ({
    name: name,
    className: `${classes} ${errors[name] && touched[name] ? "--invalid" : ""}`,
    onChange: handleChange,
    onBlur: (event)=>{
        const formatted = event.target.value &&
            !isNaN(event.target.value.toString().replace(/,/g, '')) &&
            parseFloat(event.target.value.toString().replace(/,/g, ''))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        event.target.value = formatted
        setFieldValue(name, formatted)
        handleBlur(event)
    }
})

export const checkableBoolProps = (name, classes = "", {values, errors, touched, handleChange, handleBlur, setFieldValue}) => ({
    name: name,
    checked: values[name] && !errors[name],
    className: `${classes} ${errors[name] && touched[name] ? "--invalid" : ""}`,
    onChange: ({target}) => setFieldValue(name, target.checked),
    onBlur: handleBlur
})

function getErrorsFromValidationError(validationError) {
    const FIRST_ERROR = 0
    console.log(validationError)
    return validationError?.inner?.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      }
    }, {})
}

const handleValid = (e) => {
	let delay = setInterval(() => {
		if (!e.target.classList.contains('--invalid')) {
			e.target.classList.add('--valid');
		}
		clearInterval(delay);
	}, 20);
};

export const setInputRadio = (name, classes, { values, errors, touched, handleChange, handleBlur }) => {
	return {
		name: name,
		className: `${classes} ${errors[name] && touched[name] ? '--invalid' : ''}`,
		onChange: (e) => {
			handleChange(e);
			handleValid(e);
		},
		onBlur: (e) => {
			handleBlur(e);
			handleValid(e);
		},
	};
};