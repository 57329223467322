import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './router';
import reportWebVitals from './reportWebVitals';
import './assets/scss/index.scss'
import $ from "jquery";
window.$ = $
window.jQuery = window.$
ReactDOM.render(
  <React.StrictMode>
        <Routes/>
  </React.StrictMode>,
  document.getElementById('root')
);

if(window.location.search){
  localStorage.setItem('url_query', window.location.search)
}

window.setGeneralSellerPhones = () => {

  const phones = [
    ["webinmobiliaria@formulaperu.com", 998159176], // Usher
    ["webinmobiliaria2@formulaperu.com", 998159824] // Lisbeth
  ]
  sessionStorage.setItem("seller_phone", phones[Math.floor(Math.random() * phones.length)])
}

if(!sessionStorage.getItem("seller_phone")) {
  window.setGeneralSellerPhones()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
