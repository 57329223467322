export const dataArraySanMartin = {
	project_name: 'SAN MARTIN 230',
	documents: [
		{
			type: 'file',
			name: 'Datos de proveedores',
			file: 'https://mirano.pe/documentos/sm/01.%20datos%20de%20proveedores/identificacion%20proveedor%20san%20martin%20.pdf',
		},
        {
			type: 'file',
			name: 'Ficha RUC',
			file: 'https://mirano.pe/documentos/sm/02.%20ficha%20ruc/ficha%20ruc%20vasto.pdf',
		},
        {
			type: 'file',
			name: 'Memoria descriptiva del proyecto',
			file: 'https://mirano.pe/documentos/sm/03.%20memoria%20descriptiva%20del%20proyecto/py_san%20martin%20230_md_rev3_2021.11.16.pdf',
		},
        {
			type: 'file',
			name: 'Partida registral del proyecto',
			file: 'https://mirano.pe/documentos/sm/04.%20partida%20registral%20del%20proyecto/cri%20barranco%20%28vasto%29.pdf',
		},
        {
			type: 'file',
			name: 'Poderes del proveedor',
			file: '#',
		},
        {
			type: 'file',
			name: 'Vigencia de poder',
			file: 'https://mirano.pe/documentos/sm/06.%20vigencia%20de%20poder/CRI%20Barranco%20SM.pdf',
		},
        {
			type: 'file',
			name: 'Licencia de edificación del proyecto',
			file: 'https://mirano.pe/documentos/sm/07.%20licencia%20de%20edificacion%20del%20proyecto/licencia%20de%20edificacion%20rg%20n%C2%B0%20172-2021-gdu-mdb.pdf',
		},
        {
			type: 'dir',
			name: 'Relación de acabados',
			data: [
                {
                    name: 'Barranco acabados 1 dorm con balcón <br>101,102,106,201,202,203, 204,205,208,211,<br>301,302,303,304,305,308,311,401,<br>402,403,404,405,408,411 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%201%20dorm_con%20balc%C3%B3n%20101%2C102%2C106%2C201%2C202%2C203%2C204%2C205%2C208%2C211%2C301%2C302%2C303%2C304%2C305%2C308%2C311%2C401%2C402%2C403%2C404%2C405%2C408%2C411%2010.02.23.pdf"
                },
                {
                    name: 'Barranco acabados 1 dorm duplex_ss03,ss04,ss05,ss06 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%201%20dorm_duplex_ss03%2Css04%2Css05%2Css06%2010.02.23.pdf"
                },
                {
                    name: 'Barranco acabados 1 dorm sin balcón_30.71m2 207,307,407 10.02.2023.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%201%20dorm_sin%20balc%C3%B3n_30.71m2%20207%2C307%2C407%2010.02.2023.pdf"
                },
                {
                    name: 'Barranco acabados 1 dorm sin balcón_39.19m2 107,212,312,412 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%201%20dorm_sin%20balc%C3%B3n_39.19m2%20107%2C212%2C312%2C412%2010.02.23.pdf"
                },
                {
                    name: 'Barranco acabados 1 dorm ss02,ss07,ss09,ss10 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%201%20dorm_ss02%2Css07%2Css09%2Css10%2010.02.23.pdf"
                },
                {
                    name: 'Barranco acabados 2 dorm con balcón 103,105,206,210,306,310,406,410 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%202%20dorm_con%20balc%C3%B3n%20103%2C105%2C206%2C210%2C306%2C310%2C406%2C410%2010.02.23.pdf"
                },
                {
                    name: 'Barranco acabados 2 dorm sin balcón 104,209,309,409 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%202%20dorm_sin%20balc%C3%B3n%20104%2C209%2C309%2C409%2010.02.23.pdf"
                },
                {
                    name: 'Barranco acabados 2 dorm ss08 10.02.2023.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%202%20dorm_ss08%2010.02.2023.pdf"
                },
                {
                    name: 'Barranco acabados ss01 10.02.23.pdf',
                    file:"https://mirano.pe/documentos/sm/8.%20relacion%20de%20acabados/barranco_acabados_%20ss01%2010.02.23.pdf"
                },

            ],
		},
        {
			type: 'file',
			name: 'Condiciones generales sobre el precio de compra',
			file: 'https://mirano.pe/documentos/sm/9.%20condiciones%20generales%20sobre%20el%20precio%20de%20compra/consideraciones%20sobre%20el%20precio%20de%20venta.pdf',
		},
        
        {
			type: 'dir',
			name: 'Modelo de contrato de separación',
            data: [
                {
                    name: 'A. Modelo de minuta San Martín',
                    file: 'https://mirano.pe/documentos/sm/10.%20modelo%20de%20contrato%20de%20separacion/A.%20Modelo%20de%20minuta%20San%20Martin.docx.pdf'
                },
                {
                    name: 'B. Modelo de separación San Martín',
                    file: 'https://mirano.pe/documentos/sm/10.%20modelo%20de%20contrato%20de%20separacion/B.%20Modelo%20de%20separaci%C3%B3n%20San%20Martin.docx.pdf'
                },
            ]
		},
        {
			type: 'file',
			name: 'Recomendaciones generales',
			file: '#',
		},
        {
			type: 'file',
			name: 'HR Y PU 2024',
			file: 'https://mirano.pe/documentos/sm/12.%20hr%20y%20pu%202024/hr%20-%20pu%202024%20vasto.pdf'
		},


        {
			type: 'dir',
			name: 'Comprobante de pago del impuesto predial del 2023',
			data: [
                {
                    name: 'Pago predial 2023.pdf',
                    file: 'https://mirano.pe/documentos/sm/13.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/pago%20predial%202023.pdf'
                },
                {
                    name: 'Predial 2024.pdf',
                    file: 'https://mirano.pe/documentos/sm/13.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/predial%202024.pdf'
                },
            ]
		},
        
        {
			type: 'dir',
			name: 'Planos de proyecto aprobados por la municipalidad',
			data: [
                {
                    name: 'A. Arquitectura',
                    file:"https://mirano.pe/documentos/sm/14.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/arquitectura.zip"
                },
                {
                    name: 'B. Electricas',
                    file:"https://mirano.pe/documentos/sm/14.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/electricas.zip"
                },
                {
                    name: 'C. Estructuras',
                    file:"https://mirano.pe/documentos/sm/14.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/estructuras.zip"
                },
                {
                    name: 'D. Sanitarias',
                    file:"https://mirano.pe/documentos/sm/14.%20planos%20de%20proyecto%20aprobados%20por%20la%20municipalidad/sanitarias.zip"
                },
            ],
		},
        {
			type: 'dir',
			name: 'Factibilidad de servicios',
			data: [
                {
                    name: 'LDS',
                    file: 'https://mirano.pe/documentos/sm/15.%20factibilidad%20de%20servicios/luz_del_sur.zip'
                },
                {
                    name: 'Sedapal',
                    file: 'https://mirano.pe/documentos/sm/15.%20factibilidad%20de%20servicios/sedapal.zip'
                },
            ]
		},
        {
			type: 'dir',
			name: 'Conformidad de obra',
			data: [
                {
                    name: 'Conformidad obra.pdf',
                    file:"https://mirano.pe/documentos/sm/16.%20conformidad%20de%20obra/certificado%20de%20conformidad.zip"
                },
                {
                    name: 'Planos de conformidad.zip',
                    file:"https://mirano.pe/documentos/sm/16.%20conformidad%20de%20obra/planos%20de%20conformidad.zip"
                },
            ],
		},
       
    ]
}