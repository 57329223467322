import { Link } from "react-router-dom"
import "./consumidor.scss"
export default function Consumidor(){
    const closeModal = () => {
        let modal = document.querySelector(".modal");
        modal.classList.remove("active");
    }
    return(
        <div className='consumidor'>
            <div className='icon-close mask' onClick={()=>{closeModal()}}></div>
            <h3 className="title">PROTECCIÓN AL CONSUMIDOR</h3>
            <span className='sub-title'>Seleccione su proyecto de interés</span>
            <div className='items'>
                <a onClick={()=>{closeModal()}} href={"/proteccion-al-consumidor/san-martin-230"} className='item'>
                    SAN MARTÍN 230
                </a>
                <a onClick={()=>{closeModal()}} href={"/proteccion-al-consumidor/monte-umbroso-122"} className='item'>
                    MONTE UMBROSO 122
                </a>
                <a onClick={()=>{closeModal()}} href={"/proteccion-al-consumidor/sucre-296"} className='item'>
                    SUCRE 296
                </a>
                {/* <Link onClick={()=>{closeModal()}} to={"/proteccion-al-consumidor/tudela-y-varela-445"} className='item'>
                    TUDELA Y VARELA 445
                </Link>
                <Link onClick={()=>{closeModal()}} to={"/proteccion-al-consumidor/pasteur-1250"} className='item'>
                    PASTEUR 1250
                </Link>
                <Link onClick={()=>{closeModal()}} to={"/proteccion-al-consumidor/villavicencio-"} className='item'>
                    VILLAVICENCIO   
                </Link> */}
            </div>
        </div>
    )
}