export const dataArrayLitoral = {
	project_name: 'LITORAL 900',
	documents: [
		{
			type: 'file',
			name: 'Datos de proveedores',
			file: 'https://mirano.pe/documentos/litoral/01.%20datos%20de%20proveedores/identificacion%20proveedor%20litoral.pdf',
		},
		{
			type: 'file',
			name: 'Ficha RUC',
			file: 'https://mirano.pe/documentos/litoral/02.%20ficha%20ruc/ficha%20ruc%20sauris.pdf',
		},
		{
			type: 'dir',
			name: 'Certificación LEED',
			data: [
                {
                    name: 'A. Certificado LEED',
                    file:"https://mirano.pe/documentos/litoral/03.%20certificacion%20leed/A.%20Certificado%20LEED.pdf"
                },
                {
                    name: 'B. Carta Certificado LEED',
                    file:"https://mirano.pe/documentos/litoral/03.%20certificacion%20leed/B.%20Carta%20Certificado%20LEED.pdf"
                },
            ],
		},
		{
			type: 'file',
			name: 'Memoria descriptiva del proyecto',
			file: 'https://mirano.pe/documentos/litoral/04.%20memoria%20descriptiva%20del%20proyecto/memoria%20descriptiva%20ovalo%20el%20ejercito.pdf',
		},
		
		{
			type: 'file',
			name: 'Partida registral del proyecto',
			file: 'https://mirano.pe/documentos/litoral/06.%20partida%20registral%20del%20proyecto/CRI%20Litoral',
		},
		
		{
			type: 'file',
			name: 'Vigencia de poder',
			file: 'https://mirano.pe/documentos/litoral/08.%20vigencia%20de%20poder/Certificado%20de%20Vigencia.pdf',
		},
		{
			type: 'file',
			name: 'Licencia de edificación del proyecto',
			file: 'https://mirano.pe/documentos/litoral/09.%20licencia%20de%20edificacion%20del%20proyecto/9.%20Licencia%20de%20edificaci%C3%B2n%20del%20proyecto.pdf',
		},
		{
			type: 'dir',
			name: 'Relación de acabados',
			data: [
				{
					name: 'Ejercito_acabados_dpto 1409_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_dpto%201409_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_dpto 1509_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_dpto%201509_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_dpto 203_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_dpto%20203_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_dpto 207_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_dpto%20207_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_dpto 208_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_dpto%20208_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 1_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo 1_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 2_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo 2_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 3_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo%203_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 4_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo 4_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 5_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo 5_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 6_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo 6_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 7_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo 7_25.04.2024.pdf',
				},
				{
					name: 'Ejercito_acabados_tipo 8_25.04.2024.pdf',
					file: 'https://mirano.pe/documentos/litoral/10.%20relacion%20de%20acabados/ejercito_acabados_tipo%208_25.04.2024.pdf',
				},
			],
		},
		{
			type: 'file',
			name: 'Condiciones generales sobre el precio de compra',
			file: 'https://mirano.pe/documentos/litoral/11.%20condiciones%20generales%20sobre%20el%20precio%20de%20compra/consideraciones%20sobre%20el%20precio%20de%20venta.pdf',
		},
		
        {
			type: 'dir',
			name: 'Modelo de contrato de separación y minuta',
			data: [
				{
					name: 'A. Modelo de acuerdo de separación - Litoral',
					file: 'https://mirano.pe/documentos/litoral/12.%20modelo%20de%20contrato%20de%20separacion%20y%20minuta/Modelo%20de%20acuerdo%20de%20separaci%C3%B3n%20-%20Litoral',
				},
				{
					name: 'B. Modelo de Contrato de CV - Litoral (PROPER)',
					file: 'https://mirano.pe/documentos/litoral/12.%20modelo%20de%20contrato%20de%20separacion%20y%20minuta/Modelo%20de%20Contrato%20de%20CV%20-%20Litoral%20%28PROPER%29',
				},
				{
					name: 'C. Modelo de minuta Litoral',
					file: 'https://mirano.pe/documentos/litoral/12.%20modelo%20de%20contrato%20de%20separacion%20y%20minuta/Modelo%20de%20minuta%20Litoral%20',
				},
			],
		},
        {
			type: 'file',
			name: 'Recomendaciones Generales',
			file: 'https://mirano.pe/documentos/litoral/13.%20recomendaciones%20generales/11.%20Recomendaciones%20generales.pdf',
		},
        {
			type: 'file',
			name: 'HR y PU 2024',
			file: 'https://mirano.pe/documentos/litoral/14.%20hr%20y%20pu%202024/hr%20-%20pu%202024%20-%20sauris.pdf',
		},
        {
			type: 'dir',
			name: 'Comprobante de pago del Impuesto  Predial del 2023',
			data: [
				{
					name:"A. Predial 2do, 3er y 4to trimestre del 2023",
					file:"https://mirano.pe/documentos/litoral/15.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/predial%202do%2C%203er%20y%204to%20trimestre%202023.pdf"
				},
				{
					name:"B. Predial y Arbitrios 1er trimestre",
					file:"https://mirano.pe/documentos/litoral/15.%20comprobante%20de%20pago%20del%20impuesto%20predial%20del%202023/predial%20y%20arbitrios%201er%20trimestre.pdf"
				},
			],
		},
		{
			type: 'dir',
			name: 'Planos de anteproyecto aprobado por la municipalidad ',
			data: [
				{
					name:"A. Arquitectura",
					file:"https://mirano.pe/documentos/litoral/16.%20planos%20del%20anteproyecto/14.1%20ARQUITECTURA.zip"
				},
				{
					name:"B. Electricas",
					file:"https://mirano.pe/documentos/litoral/16.%20planos%20del%20anteproyecto/14.2%20IIEE.zip"
				},
				{
					name:"C. Estructuras",
					file:"https://mirano.pe/documentos/litoral/16.%20planos%20del%20anteproyecto/14.3%20ESTRUCTURAS.zip"
				},
				{
					name:"D. Sanitarias",
					file:"https://mirano.pe/documentos/litoral/16.%20planos%20del%20anteproyecto/14.4%20IISS.zip"
				},
				{
					name:"E. Seguridad",
					file:"https://mirano.pe/documentos/litoral/16.%20planos%20del%20anteproyecto/14.5%20SEGURIDAD.zip"
				},
			],
		},
		{
			type: 'dir',
			name: 'Factibilidad de servicio de energía',
			data: [
				{
					name:"LDS",
					file:"https://mirano.pe/documentos/litoral/17%20Factibilidad%20de%20energia/15.1%20LDS.zip",
				},
				{
					name:"Sedapal",
					file:"https://mirano.pe/documentos/litoral/17%20Factibilidad%20de%20energia/15.2%20SEDAPAL.zip",
				},
			]
		},
		{
			type: 'file',
			name: 'Conformidad de Obra',
			file: 'https://mirano.pe/documentos/litoral/18.Conformidad%20de%20Obra/16.%20Litoral_FUE%20Coformidad%20de%20Obra.pdf',
		}

	],
};
