import MediaQuery from 'react-responsive';
import { FormContainer, checkableBoolProps, setInputProps } from '../../component/common/forms/Form';
import { HomeValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import './proximamente.scss'
import '../promos/promos.scss'
import { useEffect } from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import Data from '../../data/data';
import { Helmet } from 'react-helmet';
import {useHistory} from 'react-router-dom'
import { MailService } from '../../component/common/services/MailService';
import { BienvenidaEmail } from '../../component/common/Mails/BienvenidaEmail';
import OwlCarousel from "react-owl-carousel2"
export const Proximamente=({project})=>{
    const history = useHistory()
    const mailService = new MailService()
    const initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        project: "PRÓXIMAMENTE",
        terms: false,
    }
    const events = {onDragged:function(item){},onChanged:function(item){}};
    const options = {
        rewind: true,
        loop:true,
        nav:false,
        center:false,
        autoWidth:false,
        speed:1000,
        smartSpeed:300,
        dots:false,
        items:1,
        startPosition:0,
        slideBy:1,
        autoplaySpeed:1000,
        autoplayTimeout:6000,
        autoplay:true
    }
    const submitForm = async (values,form, context)=>{
        const {setSubmitting,resetForm} = form
        try {            
            // context.showModal("PRÓXIMAMENTE")
            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

            new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))

            if(values.district) values.observation = `Distrito de procedencia: ${values.district}`

            
                
            await axios.post("https://fbleads.formulaperu.com/api/webhooks/ea3d09cb-bf25-49c7-8083-22196845da72", {
                "sheet_id": "14REPqa0jWd-zIjm6mubfty4spw2N7143ufvg0zV_lAY",
                "range": "Mirano Web!A:XX",
                "rows": [[
                    date, values.fname, values.lname, values.telephone, values.email, values.project == "PRÓXIMAMENTE" ? "Litoral 900" : values.project, "",
                    values.district, values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]]
            })

            await mailService.from(`Mirano Inmobiliaria <no-reply@mirano.pe>`)
                .to(values.email)
                .subject(`Hola ${values.fname} ¡Ya eres parte de la Lista Preferente!`)
                .html(BienvenidaEmail(values))
                .send()

            await axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
                "environment": "mirano",
                "lead": {
                    "IdProyecto": project.crm.id,
                    "Nombres": values.fname,
                    "Apellidos": values.lname,
                    "Correo": values.email,
                    "Celular": values.telephone,
                    "Comentario": values.observation,
                    ...project.crm.id !== 1536 && {"IdTipoPortal": 10},
                    "url_query": localStorage.getItem('url_query')
                }
            })
            
            history.push('/Litoral-900/gracias')

            window.dataLayer && project.adsEvent && window.dataLayer.push({'event': project.adsEvent})
            window.dataLayer && window.dataLayer.push({
                'event': 'LeadCreated',
                'source': 'Form',
                'project': values.project,
            })

            window.fbq && window.fbq('track', 'Lead', {project: values.project})
            
            resetForm()
            project.crm.from_mail_wsp && window.setGeneralSellerPhones()
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: '',
                text: '¡Error! Intentelo más tarde',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: 'red'
            })
        } finally {
            setSubmitting(false)
        }
    }
    useEffect(()=>{
        let delay = setInterval(()=>{
            document.querySelector(".navbar")?.classList?.add("active")
            clearInterval(delay)
        },100)
    },[])
    return(
         <Data.Consumer>
            {context=>(
            <main className="">
                <div className='template proximamente'>
                        <div className='img-background'>
                            <MediaQuery maxWidth={499}>
                                <div class="form_content">
                                    <img className='logo' src={require('../../assets/images/proximamente/logo-brand-white.svg').default} alt="Miraflores"/>
                                </div>
                            </MediaQuery>
                            <OwlCarousel options={options} events={events} className="owl-carousel">
                                <picture>
                                    <source media="(min-width: 500px)" srcSet={require('../../assets/images/proximamente/background-landing.jpg')}></source>
                                    <img className='img' src={require('../../assets/images/proximamente/background-landing-mobile.jpg')} alt="Vista nuevo proyecto en Miraflores de Mirano Inmobiliaria"></img>
                                </picture>
                                <picture>
                                    <source media="(min-width: 500px)" srcSet={require('../../assets/images/proximamente/background-landing-2.jpg')}></source>
                                    <img className='img' src={require('../../assets/images/proximamente/background-landing-2-mobile.jpg')} alt="Vista nuevo proyecto en Miraflores de Mirano Inmobiliaria"></img>
                                </picture>
                            </OwlCarousel>
                        </div>
                        <div className='plane'>
                            <img className='bg-top' src={require('../../assets/images/proximamente/background-top.png')} ></img>
                            <img className='bg-bottom' src={require('../../assets/images/proximamente/background-bottom.png')} ></img>
                        </div>
                        <section class="container-formulario promos">
                            <div class="container-flex container">
                                <FormContainer
                                    initialValues={initialValues}
                                    validationSchema={HomeValidatonSchema}
                                    onSubmit={(e,form)=>{submitForm(e,form,context)}}>
                                    {form => {const {handleSubmit, errors, touched, isSubmitting} = form;
                                    return(
                                        <form class="form" onSubmit={handleSubmit}>
                                            <MediaQuery minWidth={500}>
                                                <div class="form_content">
                                                    <img className='logo' src={require('../../assets/images/proximamente/brand-litoral.svg').default} alt="Miraflores"/>
                                                    <h2 class="information_title ">NUEVO LANZAMIENTO EN <span>MIRAFLORES</span></h2>
                                                    <span className=' paragraph'>Suscríbete y sé parte de los primeros en recibir la información de un proyecto que inspira. </span>
                                                </div>
                                            </MediaQuery>
                                            <MediaQuery maxWidth={499}>
                                                <h2 class="information_title ">NUEVO LANZAMIENTO EN <span>MIRAFLORES</span></h2>
                                                <h3 className='paragraph'>Suscríbete y sé parte de los primeros en recibir la información de un proyecto que inspira.</h3>
                                            </MediaQuery>
                                            <div class="form_register">
                                                <input type="text" {...setInputProps("fname", "form_register_input", form)} placeholder="NOMBRE*"></input>
                                                <input type="text" {...setInputProps("lname", "form_register_input", form)} placeholder="APELLIDO*"></input>
                                                <input type="email" {...setInputProps("email", "form_register_input", form)} placeholder="E-MAIL*"></input>
                                                <input type="tel" {...setInputProps("telephone", "form_register_input", form)} placeholder="TELÉFONO*"></input>
                                            </div>
                                            
                                            <div class="form_send">
                                                
                                                <div class="content-input terms">
                                                    <input id="term" {...checkableBoolProps("terms", "checkbox", form)} type="checkbox"/>
                                                    <label class="text-term" for="term">He leído y acepto la&nbsp;<strong>
                                                    <a target="_blank" href="/Mirano-Politicas-de-privacidad.pdf">política de datos personales</a></strong>
                                                    </label>
                                                </div>
                                            </div>
                                            <button type="submit" class="form_send_button" disabled={isSubmitting}>{isSubmitting ? 'ENVIANDO' : 'ENVIAR'}</button>
                                        </form>)}}
                                </FormContainer>
                            </div>
                        </section>
                        {/* <MediaQuery query="(max-width:499px)">
                            <p className='paragraph-down'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </MediaQuery> */}
                    </div>
            </main>

            )}
        </Data.Consumer>
    )
}
