import { PROJECTS, PROJECT_CRM } from "./data";

const dataInicio = {
    slider:[
        {img:require("../assets/images/inicio/slider/slider-1-mobile.png"),imgDesktop:require("../assets/images/inicio/slider/slider-1-desktop.png"),paragraph:"",svg:true},
        {img:require("../assets/images/inicio/slider/banner-mobile-litoral.png"),imgDesktop:require("../assets/images/inicio/slider/compress-banner-web-litoral.webp"),paragraph:"", link: "/Litoral-900"},
        // {img:require("../assets/images/inicio/slider/movil/compress-mirano-2.webp"),imgDesktop:require("../assets/images/inicio/slider/mirano-2.webp"),paragraph:"<span class='title-img'>ARQUITECTURA</span><span class='sub-title-img'> que conecta</span>"},
        // {img:require("../assets/images/inicio/slider/movil/compress-mirano-3.webp"),imgDesktop:require("../assets/images/inicio/slider/mirano-3.webp"),paragraph:"<span class='title-img'>ARQUITECTURA</span><span class='sub-title-img'> que conecta</span>"},
        // {img:require("../assets/images/inicio/slider/movil/mirano-4.jpg"),imgDesktop:require("../assets/images/inicio/slider/mirano-4.jpg"),paragraph:"<span class='title-img'>ARQUITECTURA</span><span class='sub-title-img'> que conecta</span>"},
    ],
    proyectos: [
        {
            brand: require("../assets/images/icons/icon-brand/51-1-arquitectos.jpg"),
            img: require("../assets/images/proyectos/compress-litoral-900-fachada.webp"),
            title: "LITORAL 900",
            crm: PROJECT_CRM[PROJECTS.l900],
            distrito: "MIRAFLORES",
            characteristic: ["<span class='bold'>EN CONSTRUCCIÓN</span>"," 1 Y 2  DORMS","DESDE 55M² HASTA 98M²"],
            entrega: {
                year: "",
                disigner: "51-1 ARQUITECTOS"
            },
            link: "https://litoral900.pe/",
            featuresDesk: "MIRAFLORES | 1 Y 2 DORMS | DESDE 55M² HASTA 98M²",
            evoltaEmail: "litoral_900_padova@evolta.pe",
            delivery: "En Construcción"
        },
       
        {
            brand: require("../assets/images/icons/icon-brand/dlps.jpg"),
            img: require("../assets/images/proyectos/MU.png"),
            title: PROJECTS.mu122,
            crm: PROJECT_CRM[PROJECTS.mu122],
            adsEvent: "GAdsConversionMU",
            distrito: "CHACARILLA",
            characteristic: ["<span class='bold'>EN CONSTRUCCIÓN</span>","VISTA AL PARQUE", "13 DEPARTAMENTOS", "2 DORMS", "217M²"],
            entrega: {
                year: "2023",
                disigner: "DE LA PIEDRA ARQUITECTOS"
            },
            link: "/Chacarilla/Monte-Umbroso-122",
            featuresDesk: "CHACARILLA | 2 DORMS | DE 217M²",
            evoltaEmail: "monte_umbroso_122@evolta.pe",
            delivery: "Entrega Inmediata - Último Depa"
        },
        
        // {
        //     brand: require("../assets/images/icons/icon-brand/mario-lara.jpg"),
        //     img: require("../assets/images/proyectos/tudelayvarela-render.png"),
        //     title: PROJECTS.tyv445,
        //     crm: PROJECT_CRM[PROJECTS.tyv445],
        //     distrito: "MIRAFLORES",
        //     characteristic: ["<span class='bold'>ENTREGA INMEDIATA</span>","ÚLTIMO DEPA","20 DEPARTAMENTOS", "2 DORMS", "154 M²"],
        //     entrega: {
        //         year: "",
        //         disigner: "MARIO LARA ARQUITECTOS"
        //     },
        //     link: "/Miraflores/Tudela-y-Varela-445",
        //     featuresDesk: "MIRAFLORES | 2 DORMS | 154M² | ÚLTIMO DEPA",
        //     evoltaEmail: "padova_tudelavarela445@evolta.pe",
        //     delivery: "ENTREGA INMEDIATA"
        // },
        // {
        //     brand: require("../assets/images/icons/icon-brand/dlps.jpg"),
        //     img: require("../assets/images/proyectos/671056381-1228.png.webp"),
        //     title: PROJECTS.p1228,
        //     crm: PROJECT_CRM[PROJECTS.p1228],
        //     distrito: "LINCE",
        //     characteristic: ["<span class='bold'>ENTREGA INMEDIATA</span>","ULTIMOS DEPAS", "22 DEPARTAMENTOS", "1 Y 2 DORMITORIOS", "48M² - 150M²"],
        //     entrega: {
        //         year: "",
        //         disigner: "DE LA PIEDRA ARQUITECTOS"
        //     },
        //     link: "/Lince/Pasteur-1228",
        //     featuresDesk: "LINCE | 2 DORMS | 75M² | <span class='c-red'>ÚLTIMO DEPA</span>",
        //     evoltaEmail: "luis_pasteur_1228_padova@evolta.pe",
        //     delivery: "ENTREGA INMEDIATA"
        // },
        // {
        //     brand: require("../assets/images/icons/icon-brand/dlps.jpg"),
        //     img: require("../assets/images/proyectos/villavicencio-render.webp"),
        //     title: PROJECTS.v1320,
        //     crm: PROJECT_CRM[PROJECTS.v1320],
        //     distrito: "LINCE",
        //     characteristic: ["<span class='bold'>ENTREGA INMEDIATA</span>","<span class='c-red'>ÚLTIMO DEPA</span>", "29 DEPARTAMENTOS", "1 DORM", "115M²"],
        //     entrega: {
        //         year: "",
        //         disigner: "DE LA PIEDRA ARQUITECTOS"
        //     },
        //     link: "/Lince/Villavicencio-1320",
        //     featuresDesk: "LINCE | 1 DORM | 115M² | <span class='c-red'>ÚLTIMO DEPA</span>",
        //     evoltaEmail: "villavicencio_Mirano@evolta.pe",
        //     delivery: "ENTREGA INMEDIATA"
        // },
        //   {
        //     brand: require("../assets/images/icons/icon-brand/dlps.jpg"),
        //     img: require("../assets/images/proyectos/pasteur1250-render.png"),
        //     title: PROJECTS.p1250,
        //     crm: PROJECT_CRM[PROJECTS.p1250],
        //     distrito: "LINCE",
        //     characteristic: ["<span class='bold'>ENTREGA INMEDIATA</span>","ÚLTIMOS DEPAS", "28 DEPARTAMENTOS", "1 Y 3 DORMS", "52M² - 99M²"],
        //     entrega: {
        //         year: "",
        //         disigner: "DE LA PIEDRA ARQUITECTOS"
        //     },
        //     link: "/Lince/Pasteur-1250",
        //     featuresDesk: "LINCE | 1 DORM | 52M² | ÚLTIMO DEPA",
        //     evoltaEmail: "padova_pasteur_1250@evolta.pe",
        //     delivery: "ENTREGA INMEDIATA"
        // },
    ],
    entregados:[
        {
            title: PROJECTS.s296,
            distric: "MIRAFLORES",
            slider: [
                {
                    img:require("../assets/images/proyectos/Sucre.png"),
                },
                {
                    img:require("../assets/images/proyectos/sucre/slider/sucre-2.jpg"),
                },
                {
                    img:require("../assets/images/proyectos/sucre/slider/sucre-3.jpg"),
                }
            ]
        },
        {
            title: PROJECTS.sm230,
            distric: "BARRANCO",
            slider: [
                {
                    img:require("../assets/images/proyectos/SM.png"),
                },
                {
                    img:require("../assets/images/proyectos/sanmartin/slider/san-martin-2.jpg"),
                },
                {
                    img:require("../assets/images/proyectos/sanmartin/slider/san-martin-3.jpg"),
                }
            ]
        },
        {img: require("../assets/images/proyectos/villavicencio-render.webp"),title:"VILLAVICENCIO 1320",distric:"Lince"},
        {img: require("../assets/images/proyectos/u-pasteur1228.webp"),title:"Pasteur 1228",distric:"Lince"},
        {img: require("../assets/images/proyectos/pasteur1250-render.webp"),title:"Pasteur 1250",distric:"Lince"},
        {img: require("../assets/images/proyectos/compress-tudela-y-varela.webp"),title:"Tudela y Varela 445",distric:"Miraflores"},
        {img:require("../assets/images/proyectos/entregados/pasteur1375.webp"),title:"Pasteur 1375",distric:"Lince"},
        {img:require("../assets/images/proyectos/entregados/pasteur.webp"),title:"Pasteur Boutique",distric:"Lince"},
        {img:require("../assets/images/proyectos/entregados/pasteur1360.webp"),title:"Pasteur 1360",distric:"Lince"},
        {img:require("../assets/images/proyectos/entregados/pasteur1311.webp"),title:"Pasteur 1311",distric:"Lince"},
        {img:require("../assets/images/proyectos/entregados/robles2627.webp"),title:"Robles 2627",distric:"Lince"},
        {img:require("../assets/images/proyectos/entregados/pasteur1297.webp"),title:"Luis Pasteur 1297 ",distric:"Lince"},
        {img:require("../assets/images/proyectos/entregados/sucre472.webp"),title:"Mariscal Sucre 472",distric:"Miraflores"},
        {img:require("../assets/images/proyectos/entregados/robles2643.webp"),title:"Robles 2643",distric:"Lince"},
    ]

}
export default dataInicio;