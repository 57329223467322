export const data = [
    {
        title:'Campañas Abril 2024',
        texts:[
            {
                title:'Litoral Night',
                paragraph: `Lorem ipsum dolor sit amet, cu case decore sea, vel ut appareat scaevola. Tempor causae an has, movet epicurei oporteat an quo, sea ne stet aliquip. In vim nostrud urbanitas pertinacia, tempor quidam ea est. Affert congue interpretaris ei usu.`
            },
        ]
    }
]